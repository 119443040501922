import React, { FC, ReactElement } from 'react';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import './checkbox.css';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string | ReactElement;
};

const Checkbox: FC<Props> = ({ id, className, label, ...rest }) => (
  <>
    <label className={cx('pb-checkbox-wrapper')}>
      <input
        type="checkbox"
        {...rest}
        className={cx('pb-checkbox-input', className)}
      />
      <Typography
        className="no-select ml-5 font-medium"
        variant={TYPOGRAPHY_VARIANTS.BODY}
      >
        {label}
      </Typography>
    </label>
  </>
);

export default Checkbox;

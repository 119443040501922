type ConfigModel = NodeJS.ProcessEnv & {
  REACT_APP_BASE_URL: string;
  REACT_APP_PUBLIC_URL: string;
  REACT_APP_WS_URL: string;
  REACT_APP_ROBOT_ARM_BASE_URL: string;
  REACT_APP_SEQ_LOGGING_URL: string;
  REACT_APP_SEQ_LOGGING_API_KEY: string;
  REACT_APP_FE_CLOUD_URL: string;
  REACT_APP_THEME_KEY: string;
};

const CONFIG = process.env as ConfigModel;

export default CONFIG;

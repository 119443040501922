import React, { useState } from 'react';
import Button from 'components/button/Button';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { listFramePhotoAction } from 'store/features/app/frameSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import KeyboardDrawer from 'containers/selfe-booth/payment/KeyboardDrawer';

function GuideApi() {
  const dispatch = useAppDispatch();
  const { listFramePhotos, loadingInList } = useAppSelector(
    (state) => state.framePhoto,
  );
  const [open, setOpen] = useState(true);

  return (
    <div className="grid grid-cols-3 gap-4">
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Call api</Typography>
        <br />
        <Button
          color="default"
          onClick={() =>
            dispatch(
              listFramePhotoAction({
                pageIndex: 1,
                pageSize: 10,
              }),
            )
          }
        >
          Click
        </Button>{' '}
        | {loadingInList && <span>Loading...</span>}
        <br />
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Data</Typography>
        <br />
        <Button onClick={() => setOpen(true)}>Open keyboard</Button>
        <br />
        {JSON.stringify(listFramePhotos)}
        <KeyboardDrawer open={open} onClose={() => setOpen(false)} />
      </div>
    </div>
  );
}

export default GuideApi;

import { PREFIX_API } from 'constants/api.const';
import { BoothModel, GetBoothResponseModel } from 'models/photo/booth.model';
import { baseSliceApi } from './base.slice-api';

export const boothApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    getBoothApiAction: build.query<BoothModel | null, void>({
      query: () => ({
        url: `${PREFIX_API.API}/booth/getbooth`,
      }),
      providesTags: () => [{ type: 'Booth' as const, id: 'LIST' }],
      extraOptions: { maxRetries: 1 },
      transformResponse: (rawResult: GetBoothResponseModel) =>
        rawResult?.response || null,
    }),
  }),
});

export const { useGetBoothApiActionQuery } = boothApi;

export const {
  endpoints: { getBoothApiAction },
} = boothApi;

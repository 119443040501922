import React, { FC, HTMLAttributes, useCallback, useState } from 'react';
import { map, size } from 'lodash';
import cx from 'classnames';
import Button from 'components/button/Button';
import { Icons } from 'assets/icon/Icons';
import { BUTTON_COLORS } from 'components/button/button-utils';
import { MAX_LENGTH_COUPON } from 'constants/common.const';
import { KEYBOARD_CHARACTERS } from './keyboard-util';
import './keyboard.css';

export type KeyboardProps = HTMLAttributes<HTMLDivElement> & {
  value: string;
  maxLength?: number;
  loading?: boolean;
  handleChange?: (val: string) => void;
  handleEnter?: () => void;
};

export const Keyboard: FC<KeyboardProps> = ({
  value,
  maxLength = MAX_LENGTH_COUPON,
  loading = false,
  handleChange,
  handleEnter,
  ...props
}) => {
  const [isUpperCase, setIsUpperCase] = useState(true);

  const handleTyping = (text: string) => () => {
    size(`${value}${text}`) < maxLength && handleChange?.(`${value}${text}`);
  };
  const handleBackspace = () => {
    !!value && handleChange?.(value.substring(0, size(value) - 1));
  };
  const handleToggleUpperCase = () => {
    setIsUpperCase(!isUpperCase);
  };
  const getKeyboardCharacter = useCallback(
    (characters: string[]) =>
      !isUpperCase
        ? characters
        : map(characters, (c: string) => c.toUpperCase()),
    [isUpperCase],
  );

  return (
    <div {...props} className={cx('alphaKeyboard no-select', props?.className)}>
      <div className="alphaKeyboard--line">
        {map(getKeyboardCharacter(KEYBOARD_CHARACTERS.LINE_NUM), (key) => (
          <Button key={key} onClick={handleTyping(key)}>
            {key}
          </Button>
        ))}
        <Button
          className="alphaKeyboard-backSpace"
          color={BUTTON_COLORS.DEFAULT}
          onClick={handleBackspace}
        >
          <Icons.BackSpace />
        </Button>
      </div>
      <div className="alphaKeyboard--line">
        {map(getKeyboardCharacter(KEYBOARD_CHARACTERS.LINE1), (key) => (
          <Button key={key} onClick={handleTyping(key)}>
            {key}
          </Button>
        ))}
      </div>
      <div className="alphaKeyboard--line">
        {map(getKeyboardCharacter(KEYBOARD_CHARACTERS.LINE2), (key) => (
          <Button key={key} onClick={handleTyping(key)}>
            {key}
          </Button>
        ))}
      </div>
      <div className="alphaKeyboard--line">
        <Button
          className={cx('shift-key', {
            'shift-key-active': isUpperCase,
          })}
          color={BUTTON_COLORS.DEFAULT}
          onClick={handleToggleUpperCase}
        >
          <Icons.Shift />
        </Button>
        {map(getKeyboardCharacter(KEYBOARD_CHARACTERS.LINE3), (key) => (
          <Button key={key} onClick={handleTyping(key)}>
            {key}
          </Button>
        ))}
        <Button
          className="alphaKeyboard-enter"
          color={BUTTON_COLORS.DEFAULT}
          onClick={handleEnter}
          loading={loading}
          disabled={loading}
        >
          Enter
        </Button>
      </div>
      {/* <div className="alphaKeyboard--line1">
        <Button className="alphaKeyboard-space" onClick={handleTyping(' ')}>
          Space
        </Button>
        <Button
          className="alphaKeyboard-enter"
          color={BUTTON_COLORS.DEFAULT}
          onClick={handleEnter}
        >
          Enter
        </Button>
      </div> */}
    </div>
  );
};

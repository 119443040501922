import React from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import girlEyeBlink from 'assets/images/girl_eye_blink.png';
import './photo-guide.css';
import { Icons } from 'assets/icon/Icons';

function PhotoGuide() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);

  return (
    <div className="photo-guide">
      <Typography
        variant={TYPOGRAPHY_VARIANTS.H1}
        className="uppercase text-center font-black photo-guide-title"
      >
        {t('page:photoGuide')}
      </Typography>
      <div className="flex items-center justify-center">
        <div className="photo-guide-card">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="text-center font-extrabold photo-guide-card-title"
          >
            <Icons.CameraReplace /> Chụp ảnh
          </Typography>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="title-text group-info-wrapper-title"
            >
              {t('page:byCountdown')}:{' '}
              <span className="support-text">
                {t('page:moreFunWithCountDown')}
              </span>
            </Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="title-text group-info-wrapper-title"
            >
              {t('page:byRemote')}:{' '}
              <span className="support-text">{t('page:moreProactive')}</span>
            </Typography>
          </div>
          <Typography
            variant={TYPOGRAPHY_VARIANTS.SMALL}
            className="font-normal info-title"
          >
            {t('page:photoAmountEqualLayout')}
          </Typography>
          <Typography
            variant={TYPOGRAPHY_VARIANTS.SMALL}
            className="title-text"
          >
            {t('page:retakePhotoOneTime')}
          </Typography>
          <img
            src={girlEyeBlink}
            alt="girlEyeBlink"
            className="img-girl-blink"
          />
        </div>
        <div className="photo-guide-card">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="text-center font-extrabold photo-guide-card-title"
          >
            <Icons.CameraTaking /> {t('page:downloadFile')}
          </Typography>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="text-center support-text"
            >
              {t('page:scanQRToDownload')}
            </Typography>
          </div>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="text-center title-text"
            >
              {t('page:qrCodeExpireInDays')}
            </Typography>
          </div>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="text-center normal-text"
            >
              {t('page:iphoneScanGuide')}
            </Typography>
          </div>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="text-center normal-text"
            >
              {t('page:androidScanGuide')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGuide;

import React, { useEffect } from 'react';
import cx from 'classnames';
import { find, join, map, size } from 'lodash';
import { imageUrl, processedImageUrl } from 'helpers/url.helper';
import { INIT_FILTER_ID, PHOTO_WITH_BG_PREFIX } from 'constants/photo.const';
import { FilterPhotoModel } from 'models/photo/filter.model';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { isEqualVal } from 'helpers/string.helper';
import { useSoundContext } from 'context/SoundContext';
import { useBoothAppContext } from 'context/BoothAppContext';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import {
  FILTER_MATRIX,
  PhotoFilterTypes,
} from 'components/photo-sheet/photo-sheet-utils';
import './add-filter.css';
import { SelfeAppStateModel } from '../../../models/app.model';

function AddFilter() {
  const dispatch = useAppDispatch();
  const { audio } = useSoundContext();
  const { listFilterPhotos, themeDetail, currentLayout } = useBoothAppContext();
  const { filterId, transactionId, pickedPhotos, backgroundId } =
    useAppSelector((state: { selfeApp: SelfeAppStateModel }) => state.selfeApp);

  const currentFilter = find(listFilterPhotos?.data, (o) =>
    isEqualVal(o?.id, filterId),
  );

  const handleSelectFilter = (id: FilterPhotoModel['id']) => () => {
    if (filterId !== id) {
      audio?.playClickToSelect?.();
      dispatch(setSelfeAppStateAction({ filterId: id }));
    }
  };

  useEffect(() => {
    dispatch(
      setSelfeAppStateAction({
        filterId: filterId || listFilterPhotos?.data?.[0]?.id || INIT_FILTER_ID,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listFilterPhotos]);

  return (
    <>
      <div className="add-filter">
        <div className="add-filter__section">
          <div className="add-filter__photo-sheet--wrapper transition-img">
            <PhotoSheet
              className="add-filter__photo-sheet"
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        backgroundId
                          ? `${PHOTO_WITH_BG_PREFIX}${pickedPhotos?.[ind]?.fileName}`
                          : pickedPhotos?.[ind]?.fileName,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
                image: themeDetail?.image || currentLayout?.image,
              }}
              filterValues={currentFilter?.colorMatrix}
            />
          </div>
          <div className="add-filter__grid--wrapper">
            <div className="add-filter__grid">
              {map(listFilterPhotos?.data, (filterData: FilterPhotoModel) => (
                <button
                  type="button"
                  className={cx('filter-item', {
                    active: !!isEqualVal(filterId, filterData?.id),
                  })}
                  onClick={handleSelectFilter(filterData?.id)}
                >
                  <img
                    src={imageUrl(filterData?.image)}
                    alt="filter-img"
                    className="filter-thumbnail no-drag"
                  />
                  <svg className="thumbnail-filter-svg">
                    <filter
                      id={`filter-image-${filterData?.id}`}
                      colorInterpolationFilters="sRGB"
                    >
                      <feColorMatrix
                        type="matrix"
                        values={join(
                          size(filterData?.colorMatrix)
                            ? filterData?.colorMatrix
                            : FILTER_MATRIX?.[PhotoFilterTypes.NONE],
                        )}
                      />
                    </filter>
                    <image
                      filter={`url(#filter-image-${filterData?.id})`}
                      xlinkHref={imageUrl(filterData?.image)}
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                    />
                  </svg>
                  <Typography
                    className="filter-name"
                    variant={TYPOGRAPHY_VARIANTS.SMALL}
                  >
                    {filterData?.name}
                  </Typography>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddFilter;

import { useCallback, useState } from 'react';
import useInterval from 'beautiful-react-hooks/useInterval';
import { SECOND_VAL } from 'constants/photo.const';
import useDidMount from 'beautiful-react-hooks/useDidMount';
import { useSoundContext } from 'context/SoundContext';
import { mToSec } from 'helpers/math.helper';

type UseTimerProps = {
  timeUpFunc?: () => void;
  totalMilliseconds: number | undefined;
  millSecEnableAudio?: number;
  isEnabledAudio?: boolean;
  isEnabled?: boolean;
};

export function useTimer({
  timeUpFunc,
  totalMilliseconds,
  millSecEnableAudio = 0,
  isEnabledAudio = false,
  isEnabled = false,
}: UseTimerProps) {
  const [second, setSecond] = useState(mToSec(totalMilliseconds));
  const [mounted, setIsMounted] = useState(false);
  const { audio } = useSoundContext();

  const resetTime = useCallback(() => {
    setSecond(mToSec(totalMilliseconds));
  }, [totalMilliseconds]);

  const setTime = useCallback((sec: number) => {
    setSecond(sec);
  }, []);

  useInterval(() => {
    isEnabled && setSecond((s) => s - 1);
    if (isEnabledAudio && second <= mToSec(millSecEnableAudio) && second > 0) {
      audio?.playSecondTick?.();
    }
  }, SECOND_VAL);

  useInterval(() => {
    if (isEnabled && mounted && second === 0) {
      timeUpFunc?.();
    }
  }, SECOND_VAL);

  useDidMount(() => {
    const timeout = setTimeout(() => {
      setIsMounted(true);
      clearTimeout(timeout);
    }, 1000);
  });

  return {
    second: Math.max(second, 0),
    setSecond,
    setTime,
    resetTime,
  };
}

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ElementPhotoStateModel } from 'models/photo/element.model';

const initialState: ElementPhotoStateModel = {
  listElementPhotos: {
    data: [],
    totalRecord: 0,
  },
  loadingInList: false,
};

export const elementPhotoSlice = createSlice({
  name: 'elementPhoto',
  initialState,
  reducers: {
    setElementPhotoStateAction: (
      state,
      action: PayloadAction<Partial<ElementPhotoStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
});

export const { setElementPhotoStateAction } = elementPhotoSlice.actions;

export default elementPhotoSlice.reducer;

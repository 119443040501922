import React, { ButtonHTMLAttributes, FC, HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import Button, { ButtonProps } from 'components/button/Button';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'hooks/useTranslation';
import './page-actions.css';

export type PageActionsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  hidePrevNext?: boolean;
  hidePrev?: boolean;
  hideNext?: boolean;
  handleBack?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  handleContinue?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  PrevButtonProps?: ButtonProps;
  NextButtonProps?: ButtonProps;
};

const PageActions: FC<PageActionsProps> = ({
  hidePrevNext = false,
  hidePrev = false,
  hideNext = false,
  handleBack,
  handleContinue,
  PrevButtonProps,
  NextButtonProps,
  ...rest
}) => {
  const { T } = useTranslation(I18nNamespace.COMMON);

  return (
    <>
      {!hidePrevNext ? (
        <div {...rest} className={cx('page-actions', rest?.className)}>
          {!hidePrev && (
            <Button
              {...PrevButtonProps}
              color="default"
              onClick={PrevButtonProps?.onClick || handleBack}
            >
              {PrevButtonProps?.children || T('common:back')}
            </Button>
          )}
          {!hideNext && (
            <Button
              {...NextButtonProps}
              onClick={NextButtonProps?.onClick || handleContinue}
            >
              {NextButtonProps?.children || T('common:continue')}
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default PageActions;

import React, { FC, HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import './background.css';

export type Props = HtmlHTMLAttributes<HTMLDivElement> & {};

const Background: FC<Props> = ({ className, ...rest }) => (
  <div
    {...rest}
    className={cx(
      'w-full h-full absolute top-0 left-0 -z-50 object-cover bg-image',
      className,
    )}
  />
);

export default Background;

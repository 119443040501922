import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import { store } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from 'assets/icon/Icons';
import { useModal } from 'hooks/useModal';
import { emitSeqLog } from 'functions/seq-logging.func';
import { useTranslation } from 'hooks/useTranslation';
import { useAppContext } from 'context/AppContext';
import { LOGGING_LEVEL } from 'constants/logging.const';
import { I18nNamespace } from 'constants/i18n.const';
import { SelfeRouteConst } from 'constants/route.const';
import { isEqualVal } from 'helpers/string.helper';
import { formatMoneyVN } from 'helpers/math.helper';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import './offline-popup.css';

function OfflinePopup() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const { openErrorModal, closeConfirmModal } = useModal();
  const { isOnline } = useAppContext();
  const { pathname } = useLocation();
  const depositRef = useRef(0);
  const navigate = useNavigate();

  const handleShowOfflineWarning = useCallback(() => {
    const { depositAmount, appliedVoucherValue } =
      store.getState()?.selfeApp || {};
    depositRef.current = depositAmount - appliedVoucherValue;
    navigate(SelfeRouteConst.START_PAGE);
    emitSeqLog?.({
      level: LOGGING_LEVEL.FATAL,
      messageTemplate: `[Offline] ${T('common:noInternet')}`,
    });
    setTimeout(
      () =>
        openErrorModal(T('common:noInternet'), {
          hidePrevNext: true,
          RenderContent: () => (
            <Typography
              variant={TYPOGRAPHY_VARIANTS.BODY}
              color={TypographyColor.SECONDARY}
              firstCapCase
            >
              {T(`${I18nNamespace.COMMON}:transactionWillBeCancel`)}
              <br />
              {T(`${I18nNamespace.COMMON}:depositAmountIs`)}: <br />
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H1}
                color={TypographyColor.PRIMARY}
                firstCapCase
              >
                {formatMoneyVN(
                  Math.max(depositAmount - appliedVoucherValue, 0),
                )}{' '}
                đ
              </Typography>
              <br />
              {T(`${I18nNamespace.COMMON}:pleaseContactStaff`)}
            </Typography>
          ),
        }),
      1000,
    );
  }, [T, openErrorModal, navigate]);

  const isInStartPage = useMemo(
    () => isEqualVal(pathname, SelfeRouteConst.START_PAGE),
    [pathname],
  );

  useEffect(() => {
    if (!isOnline) {
      handleShowOfflineWarning();
    } else {
      closeConfirmModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return !isOnline ? (
    <button
      type="button"
      className={cx('btn-offline', {
        'btn-offline-in-start-page': isInStartPage,
      })}
      onClick={handleShowOfflineWarning}
      aria-label="offline warning"
    >
      <Icons.Warning />
    </button>
  ) : (
    <></>
  );
}

export default OfflinePopup;

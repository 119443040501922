import React, { HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import './timer-text.css';

export type TimerTextProps = HtmlHTMLAttributes<HTMLDivElement> & {
  second?: number;
};

const TimerText: React.FC<TimerTextProps> = ({ second = 0, ...rest }) => (
  <div
    {...rest}
    className={cx(
      'text-pb-body pb-button-primary app-timer-text',
      rest?.className,
    )}
  >
    {second}
  </div>
);

export default TimerText;

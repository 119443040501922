import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LayoutPhotoStateModel } from 'models/photo/layout.model';

// Define the initial state using that type
const initialState: LayoutPhotoStateModel = {
  listLayoutPhotos: {
    data: [],
    totalRecord: 0,
  },
  loadingInList: false,
};

export const layoutPhotoSlice = createSlice({
  name: 'layoutPhoto',
  initialState,
  reducers: {
    setLayoutPhotoStateAction: (
      state,
      action: PayloadAction<Partial<LayoutPhotoStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
});

export const { setLayoutPhotoStateAction } = layoutPhotoSlice.actions;

export default layoutPhotoSlice.reducer;

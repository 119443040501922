import StartMascot from './mascot.png';
import PrintMascot from './mascot_1.png';
import WhiteLogo from './white_logo.png';
import MomoLogo from './momo.png';
import MomoFillLogo from './momo-fill.png';
import QRMascot from './qr_mascot.png';
import CashMascot from './cash_mascot.png';

export const AssetImage = {
  StartMascot,
  PrintMascot,
  WhiteLogo,
  MomoLogo,
  MomoFillLogo,
  QRMascot,
  CashMascot,
};

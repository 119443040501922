import { PopupProps } from 'components/popup/Popup';
import { I18nNamespace } from 'constants/i18n.const';
import { INIT_POPUP_STATE } from 'constants/modal.const';
import { SelfeAppStateModel } from 'models/app.model';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch } from 'store/store-hooks';

export function useModal() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();

  const openConfirmModal = (state: SelfeAppStateModel['modalConfirmState']) => {
    dispatch(
      setSelfeAppStateAction({
        modalConfirmState: { ...INIT_POPUP_STATE, open: true, ...state },
      }),
    );
  };

  const closeConfirmModal = () => {
    dispatch(
      setSelfeAppStateAction({
        modalConfirmState: { ...INIT_POPUP_STATE },
      }),
    );
  };

  const openErrorModal = (
    errText: PopupProps['content'] | null = '',
    options: Partial<SelfeAppStateModel['modalConfirmState']> = {},
  ) => {
    openConfirmModal({
      content: errText || `${t('common:somethingWrong')}`,
      onOk: closeConfirmModal,
      hidePrev: true,
      ...options,
    });
  };

  return {
    openConfirmModal,
    closeConfirmModal,
    openErrorModal,
  };
}

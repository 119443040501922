/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, PropsWithChildren, useContext } from 'react';
import useSound from 'use-sound';
import cameraShutterAudio from 'assets/audio/camera-shutter.mp3';
import secondTickAudio from 'assets/audio/countdown-1s.mp3';
import continueClickAudio from 'assets/audio/continue-click.mp3';
import backClickAudio from 'assets/audio/back-click.mp3';
import clickToSelectAudio from 'assets/audio/click-to-select.mp3';
import depositAudio from 'assets/audio/deposit.mp3';
import voucherAppliedAudio from 'assets/audio/voucher-applied.mp3';
import voucherExpiredAudio from 'assets/audio/voucher-expired.mp3';
import retakePhotoClickAudio from 'assets/audio/retake-photo-click.mp3';
import redundantDepositAudio from 'assets/audio/redundant-deposit.mp3';

export type UseSoundEffectReturnType = {
  playSecondTick: () => void;
  playCameraShutter: () => void;
  playContinueClick: () => void;
  playBackClick: () => void;
  playClickToSelect: () => void;
  playDeposit: () => void;
  playVoucherApplied: () => void;
  playVoucherExpired: () => void;
  playRetakePhoto: () => void;
  playRedundantDeposit: () => void;
};

export const Context = React.createContext<SoundContextReturnTypes>({});

interface SoundContextProps {}
type SoundContextReturnTypes = {
  audio?: UseSoundEffectReturnType;
};

export const SoundContext: FC<PropsWithChildren<SoundContextProps>> = ({
  children,
}) => {
  const [playCameraShutterAudio, { stop: stopCameraShutterAudio }] =
    useSound(cameraShutterAudio);
  const [playSecondTickAudio, { stop: stopSecondTickAudio }] =
    useSound(secondTickAudio);

  const [playContinueClickAudio, { stop: stopContinueClickAudio }] =
    useSound(continueClickAudio);
  const [playBackClickAudio, { stop: stopBackClickAudio }] = useSound(
    backClickAudio,
    { interrupt: true },
  );
  const [playClickToSelectAudio, { stop: stopClickToSelectAudio }] =
    useSound(clickToSelectAudio);
  const [playDepositAudio, { stop: stopDepositAudio }] = useSound(depositAudio);
  const [playVoucherAppliedAudio, { stop: stopVoucherAppliedAudio }] =
    useSound(voucherAppliedAudio);
  const [playVoucherExpiredAudio, { stop: stopVoucherExpiredAudio }] =
    useSound(voucherExpiredAudio);
  const [playRetakePhotoClickAudio, { stop: stopRetakePhotoClickAudio }] =
    useSound(retakePhotoClickAudio);
  const [playRedundantDepositAudio, { stop: stopRedundantDepositAudio }] =
    useSound(redundantDepositAudio);

  const pauseAllAudio = () => {
    stopCameraShutterAudio();
    stopSecondTickAudio();
    stopContinueClickAudio();
    stopBackClickAudio();
    stopClickToSelectAudio();
    stopDepositAudio();
    stopVoucherAppliedAudio();
    stopVoucherExpiredAudio();
    stopRetakePhotoClickAudio();
    stopRedundantDepositAudio();
  };

  const playSecondTick = () => {
    pauseAllAudio();
    playSecondTickAudio();
  };

  const playCameraShutter = () => {
    pauseAllAudio();
    playCameraShutterAudio();
  };

  const playContinueClick = () => {
    pauseAllAudio();
    playContinueClickAudio();
  };

  const playBackClick = () => {
    pauseAllAudio();
    playBackClickAudio();
  };

  const playClickToSelect = () => {
    pauseAllAudio();
    playClickToSelectAudio();
  };

  const playDeposit = () => {
    pauseAllAudio();
    playDepositAudio();
  };

  const playVoucherApplied = () => {
    pauseAllAudio();
    playVoucherAppliedAudio();
  };

  const playVoucherExpired = () => {
    pauseAllAudio();
    playVoucherExpiredAudio();
  };

  const playRetakePhoto = () => {
    pauseAllAudio();
    playRetakePhotoClickAudio();
  };

  const playRedundantDeposit = () => {
    pauseAllAudio();
    playRedundantDepositAudio();
  };

  return (
    <Context.Provider
      value={{
        audio: {
          playSecondTick,
          playCameraShutter,
          playContinueClick,
          playBackClick,
          playClickToSelect,
          playDeposit,
          playVoucherApplied,
          playVoucherExpired,
          playRetakePhoto,
          playRedundantDeposit,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSoundContext = () => useContext(Context);

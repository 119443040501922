import qs from 'query-string';
import { map } from 'lodash';
import {
  FramePhotoStateModel,
  ListFramePhotoRequestModel,
  ListFramePhotoResponseModel,
} from 'models/photo/frame.model';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';

export const framesApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listFrameApiAction: build.query<
      FramePhotoStateModel['listFramePhotos'],
      ListFramePhotoRequestModel | null
    >({
      query: (payload = null) => ({
        url: `${PREFIX_API.API}/frame/getlist?${
          payload && qs.stringify(payload)
        }`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'FramePhoto', id } as const)),
        { type: 'FramePhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: ListFramePhotoResponseModel, meta) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const { useListFrameApiActionQuery, useLazyListFrameApiActionQuery } =
  framesApi;

export const {
  endpoints: { listFrameApiAction },
} = framesApi;

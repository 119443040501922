export const TIME_OUT_API = 30000;
export const PREFIX_API = {
  ROBOT_ARM_API: 'api_v1',
  API: 'api',
  FILE: 'file',
};
export const CAMERA_DEVICE_API = {
  CANON: 'canon',
  WEBCAM: 'cam',
};

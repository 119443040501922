import qs from 'query-string';
import { map } from 'lodash';
import { PREFIX_API } from 'constants/api.const';
import {
  FilterPhotoStateModel,
  GetFilterPhotoResponseModel,
  ListFilterPhotoRequestModel,
  ListFilterPhotoResponseModel,
} from 'models/photo/filter.model';
import { baseSliceApi } from './base.slice-api';

export const filtersApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listFilterApiAction: build.query<
      FilterPhotoStateModel['listFilterPhotos'],
      ListFilterPhotoRequestModel | null
    >({
      query: (payload = null) => ({
        url: `${PREFIX_API.API}/filter/getlist?${
          payload && qs.stringify(payload)
        }`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'FilterPhoto', id } as const)),
        { type: 'FilterPhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: ListFilterPhotoResponseModel) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getFilterApiAction: build.query<
      FilterPhotoStateModel['filterPhotoDetail'],
      ListFilterPhotoRequestModel | null
    >({
      query: (payload = null) => ({
        url: `${PREFIX_API.API}/filter/getlist?${
          payload && qs.stringify(payload)
        }`,
      }),
      providesTags: (data) => [{ type: 'FilterPhoto' as const, id: data?.id }],
      transformResponse: (rawResult: GetFilterPhotoResponseModel) =>
        rawResult?.response ?? null,
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const { useListFilterApiActionQuery, useLazyListFilterApiActionQuery } =
  filtersApi;

export const {
  endpoints: { listFilterApiAction },
} = filtersApi;

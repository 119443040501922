import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import { mToSec } from 'helpers/math.helper';
import { useAppSelector } from 'store/store-hooks';
import { PLAY_AUDIO_TICK_SECOND_IN_LAST } from 'constants/photo.const';
import { emitSeqLog } from 'functions/seq-logging.func';
import { useNavigate } from 'react-router';
import { useTimer } from './useTimer';
import { useNewPhotoLifeCycleStep } from './useNewPhotoLifeCycleStep';
import { useModal } from './useModal';

type UsePageTimerProps = {
  forwardFunc?: () => void;
  isNotShowConfirm?: boolean;
  checkDepositAmount?: boolean;
  isBeforeTransaction?: boolean;
};

export function usePageTimer({
  forwardFunc,
  isNotShowConfirm = false,
  checkDepositAmount = false,
  isBeforeTransaction = false,
}: UsePageTimerProps = {}) {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { getStartNodePath, getNextPath, currentRoute } =
    useNewPhotoLifeCycleStep();
  const { openConfirmModal, closeConfirmModal } = useModal();
  const { depositAmount } = useAppSelector((state) => state.selfeApp);

  const { second, setTime, resetTime } = useTimer({
    totalMilliseconds: currentRoute?.timeUpDuration,
    isEnabled: true,
    isEnabledAudio: true,
    millSecEnableAudio: PLAY_AUDIO_TICK_SECOND_IN_LAST,
    timeUpFunc: () => handleTimeUp(),
  });

  const handleConfirmedTimeUp = () => {
    emitSeqLog?.({
      messageTemplate: `[Path: ${window?.location?.pathname}] Time up!`,
      properties: { currentRoute },
    });
    closeConfirmModal?.();
    if (currentRoute?.forwardAfterTimeUp && forwardFunc) {
      forwardFunc?.();
    } else if (currentRoute?.forwardAfterTimeUp) {
      navigate(getNextPath() || '');
    } else if (checkDepositAmount && depositAmount > 0) {
      // no action
    } else {
      navigate(getStartNodePath() || '');
    }
  };

  // handle time up
  const handleTimeUp = () => {
    if (isNotShowConfirm) {
      handleConfirmedTimeUp();
    } else {
      openConfirmModal({
        content: isBeforeTransaction
          ? `${t('common:warningCancelTransaction')}`
          : `${t('common:doYouNeedMoreTime')}`,
        PrevButtonProps: { children: t('common:no') },
        NextButtonProps: !isBeforeTransaction
          ? { children: t('common:yes') }
          : {},
        duration: currentRoute?.moreTimeUpDuration,
        isUseTimer: true,
        onTimeUp: handleConfirmedTimeUp,
        onOk: () => {
          closeConfirmModal?.();
          setTime(mToSec(currentRoute?.timeUpDuration));
        },
        onClose: handleConfirmedTimeUp,
        isTimerInBtnNext: isBeforeTransaction,
        hidePrev: isBeforeTransaction,
      });
    }
  };

  return {
    second,
    resetTime,
    handleTimeUp,
  };
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  FramePhotoStateModel,
  ListFramePhotoRequestModel,
} from 'models/photo/frame.model';
import { listFramePhoto } from 'api/photo/frame.api';
import { listFrameApiAction } from 'store/api/frame.slice-api';

const initialState: FramePhotoStateModel = {
  listFramePhotos: {
    data: [],
    totalRecord: 0,
  },
  loadingInList: false,
};

// Actions
export const listFramePhotoAction = createAsyncThunk(
  'LIST_FRAME_ACTION_REQUEST',
  async (payload: ListFramePhotoRequestModel) => listFramePhoto(payload),
);

// Slice
export const framePhotoSlice = createSlice({
  name: 'framePhoto',
  initialState,
  reducers: {
    setFramePhotoStateAction: (
      state,
      action: PayloadAction<Partial<FramePhotoStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(listFrameApiAction.matchPending, (state, action) => ({
        ...state,
        listFramePhotos: { ...initialState.listFramePhotos },
        loadingInList: true,
      }))
      .addMatcher(listFrameApiAction.matchFulfilled, (state, { payload }) => ({
        ...state,
        listFramePhotos: {
          data: payload?.data ?? [],
          totalRecord: payload?.totalRecord ?? 0,
        },
        loadingInList: false,
      }))
      .addMatcher(listFrameApiAction.matchRejected, (state, action) => ({
        ...state,
        listFramePhotos: { ...initialState.listFramePhotos },
        loadingInList: false,
      }));
  },
});

export const { setFramePhotoStateAction } = framePhotoSlice.actions;

export default framePhotoSlice.reducer;

import cx from 'classnames';
import Typography, {
  Props as TypographyPropsProps,
} from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import React, { FC } from 'react';
import { BUTTON_COLORS } from './button-utils';
import './button.css';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: string;
  TypographyProps?: TypographyPropsProps;
  loading?: boolean;
  noBorder?: boolean;
};

const Button: FC<ButtonProps> = ({
  className,
  color = BUTTON_COLORS.PRIMARY,
  children,
  TypographyProps,
  disabled,
  loading,
  noBorder,
  ...rest
}) => (
  <button
    {...rest}
    disabled={disabled}
    type="button"
    className={cx(
      'pb-button',
      {
        'pb-button-info': color === BUTTON_COLORS.INFO,
        'pb-button-primary': color === BUTTON_COLORS.PRIMARY,
        'pb-button-default': color === BUTTON_COLORS.DEFAULT,
        'pb-button-transparent': color === BUTTON_COLORS.TRANSPARENT,
        'pb-button-disabled': disabled,
        'pb-button-no-border': noBorder,
      },
      className,
    )}
  >
    <Typography
      variant={TYPOGRAPHY_VARIANTS.BODY}
      firstCapCase
      {...TypographyProps}
      className={cx('pb-button-text', TypographyProps?.className)}
    >
      {loading ? (
        <div className="indi-spiner">
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        children
      )}
    </Typography>
  </button>
);

export default Button;

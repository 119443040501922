import { map } from 'lodash';
import {
  ListPhotoTakenRequestModel,
  ListPhotoTakenResponseModel,
  PhotoTakenStateModel,
  ProcessPhotoTakenRequestModel,
  ProcessPhotoTakenResponseModel,
  ProcessVideoTakenRequestModel,
  ProcessVideoTakenResponseModel,
} from 'models/photo/photo-taken.model';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';

export const photoTakenApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listPhotoTakenApiAction: build.query<
      PhotoTakenStateModel['listPhotoTakens'],
      ListPhotoTakenRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/file/images/${payload?.transactionId}`,
      }),
      providesTags: (data) => [
        ...map(data?.data, (img) => ({ type: 'PhotoTaken', id: img } as const)),
        { type: 'PhotoTaken' as const, id: 'LIST' },
      ],
      extraOptions: { maxRetries: 0 },
      transformResponse: (rawResult: ListPhotoTakenResponseModel) => ({
        data:
          rawResult?.response?.data?.filter(
            (i) => !i.includes('origin_') && !i.includes('removebg'),
          ) ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
    }),
    processPhotoTakenApiAction: build.mutation<
      ProcessPhotoTakenResponseModel,
      ProcessPhotoTakenRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.API}/file/processimage`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    processVideoTakenApiAction: build.mutation<
      ProcessVideoTakenResponseModel,
      ProcessVideoTakenRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.API}/file/processvideo`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useListPhotoTakenApiActionQuery,
  useProcessPhotoTakenApiActionMutation,
  useProcessVideoTakenApiActionMutation,
} = photoTakenApi;

export const {
  endpoints: { listPhotoTakenApiAction, processPhotoTakenApiAction },
} = photoTakenApi;

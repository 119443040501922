import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { I18nNamespace } from 'constants/i18n.const';
import { SHOOTING_TYPES } from 'constants/photo.const';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { photoTakenApi } from 'store/api/photo-taken.slice-api';
import { camerasApi } from 'store/api/camera.slice-api';
import { setTrackingStateAction } from 'store/features/app/trackingSlice';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import cdDemNguocImage from 'assets/v2/images/clock.png';
import cdDieukhienImage from 'assets/v2/images/gaming_control.png';
import Typography, { TypographyColor } from 'components/typography/Typography';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { useSoundContext } from 'context/SoundContext';
import './shooting-mode.css';

function ShootingMode() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getNextPath } = useNewPhotoLifeCycleStep();

  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  const { second } = usePageTimer({
    forwardFunc: () => handleRedirectSelectLayout(SHOOTING_TYPES.COUNTDOWN)(),
  });

  const handleRedirectSelectLayout = (id: number) => async () => {
    audio?.playContinueClick?.();
    dispatch(setSelfeAppStateAction({ shootingModeId: id }));
    navigate(getNextPath() || '');
  };

  // Start live view
  useEffect(() => {
    dispatch(setTrackingStateAction({ LastStartTimeTakePhoto: new Date() }));
    dispatch(camerasApi.util.invalidateTags([{ type: 'Camera' }]));
    dispatch(photoTakenApi.util.invalidateTags([{ type: 'PhotoTaken' }]));
  }, [dispatch]);

  return (
    <>
      <div className="shooting-mode">
        <TimerText second={second} />
        {!isVerticalScreen && (
          <div className="page-title-margin">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={t(`${I18nNamespace.COMMON}:shootingMode`)}
              className="page-title"
            >
              {t(`${I18nNamespace.COMMON}:shootingMode`)}
            </Typography>
          </div>
        )}
        <div className="shooting-mode__btn-group">
          <div className="dashed-border-select-frame shooting-mode__item">
            <button
              type="button"
              className="shooting-mode__btn"
              onClick={handleRedirectSelectLayout(SHOOTING_TYPES.COUNTDOWN)}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="text-center font-bold card-title"
                color={TypographyColor.SECONDARY}
                upperCase
              >
                {t('page:byCountdown')}
              </Typography>
              <div className="card-wrapper-img">
                <img src={cdDemNguocImage} alt="example-img" />
              </div>
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="text-center font-semibold"
                color={TypographyColor.SECONDARY}
                upperCase
              >
                {t('page:hasRecordVideo')}
              </Typography>
            </button>
          </div>
          <div
            className={
              'dashed-border-select-frame shooting-mode__item shooting-mode__item--right'
            }
          >
            <button
              type="button"
              className="shooting-mode__btn"
              onClick={handleRedirectSelectLayout(SHOOTING_TYPES.BY_REMOTE)}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="text-center font-bold card-title"
                color={TypographyColor.SECONDARY}
                upperCase
              >
                {t('page:byRemote')}
              </Typography>
              <div className="card-wrapper-img">
                <img src={cdDieukhienImage} alt="example-img" />
              </div>
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="text-center font-semibold"
                color={TypographyColor.SECONDARY}
                upperCase
              >
                {t('page:noHasRecordVideo')}
              </Typography>
            </button>
          </div>
        </div>
      </div>
      {isVerticalScreen && (
        <TitleAndLogo
          className="shooting-mode__title"
          text={t(`${I18nNamespace.COMMON}:shootingMode`)}
        />
      )}
    </>
  );
}

export default ShootingMode;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PhotoTakenStateModel } from 'models/photo/photo-taken.model';
import { listPhotoTakenApiAction } from 'store/api/photo-taken.slice-api';

// Define the initial state using that type
const initialState: PhotoTakenStateModel = {
  listPhotoTakens: {
    data: [],
    totalRecord: 0,
  },
};

export const photoTakenSlice = createSlice({
  name: 'photoTakenSlice',
  initialState,
  reducers: {
    setPhotoTakenStateAction: (
      state,
      action: PayloadAction<Partial<PhotoTakenStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(listPhotoTakenApiAction.matchPending, (state) => ({
        ...state,
        listPhotoTakens: { ...initialState.listPhotoTakens },
      }))
      .addMatcher(
        listPhotoTakenApiAction.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          listPhotoTakens: {
            data: payload?.data ?? [],
            totalRecord: payload?.totalRecord ?? 0,
          },
        }),
      )
      .addMatcher(listPhotoTakenApiAction.matchRejected, (state) => ({
        ...state,
        listPhotoTakens: { ...initialState.listPhotoTakens },
      }));
  },
});

export const { setPhotoTakenStateAction } = photoTakenSlice.actions;

export default photoTakenSlice.reducer;

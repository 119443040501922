import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { tokenManager } from 'api/request/tokenManager';
import CONFIG from 'config';
import { TIME_OUT_API } from 'constants/api.const';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.REACT_APP_BASE_URL,
  timeout: TIME_OUT_API,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const { token } = tokenManager;
    if (token) {
      headers.set('Authentication', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const baseSliceApi = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'Booth',
    'FramePhoto',
    'CapturePosition',
    'LayoutPhoto',
    'ThemePhoto',
    'StickerPhoto',
    'ThemeDetailPhoto',
    'ThemeCategoryPhoto',
    'StickerCategoryPhoto',
    'PrintPhoto',
    'PhotoTaken',
    'FilterPhoto',
    'Camera',
    'BillAcceptor',
    'Ratio',
    'Transaction',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});

export const enhancedApi = baseSliceApi.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
});

// Create our baseQuery instance
const robotArmBaseQuery = fetchBaseQuery({
  baseUrl: CONFIG.REACT_APP_ROBOT_ARM_BASE_URL,
  timeout: TIME_OUT_API,
  prepareHeaders: (headers) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    headers.set('Content-Type', 'application/json');
    const { token } = tokenManager;
    if (token) {
      headers.set('Authentication', `Bearer ${token}`);
    }
    return headers;
  },
  fetchFn: (input, init) => {
    // Modify the fetch options to include no-cors if necessary
    if (init) {
      // eslint-disable-next-line no-param-reassign
      (init as any).mode = 'no-cors'; // Using 'cors' instead of 'no-cors' for broader functionality
    }
    return fetch(input, init);
  },
});

export const robotArmBaseSliceApi = createApi({
  reducerPath: 'robotArmBaseSliceApi',
  baseQuery: robotArmBaseQuery,
  tagTypes: ['RobotArm'],
  endpoints: () => ({}),
});

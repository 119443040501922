import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { filter, find, map, range, slice } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { UseCarouselTypes } from 'hooks/useCarousel';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import {
  INIT_STICKER_CATEGORY_ID,
  ITEM_CATEGORY_SELECTABLE_PER_PAGE,
  ITEM_SELECTABLE_PER_PAGE,
  PHOTO_WITH_BG_PREFIX,
  SHOOTING_TYPES,
  VERTICAL_SCREEN_ITEM_SELECTABLE_PER_PAGE,
} from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { Carousel } from 'components/carousel/Carousel';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import Button from 'components/button/Button';
import { processedImageUrl, resourceUrl } from 'helpers/url.helper';
import { isEqualVal } from 'helpers/string.helper';
import './select-sticker.css';

function SelectSticker() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const { audio } = useSoundContext();
  const {
    listStickerCategoryPhotos,
    themeDetail,
    currentLayout,
    listStickerPhotos,
    listFilterPhotos,
  } = useBoothAppContext();
  const {
    transactionId,
    pickedPhotos,
    stickerCategoryId,
    listSelectedSticker,
    shootingModeId,
    photoContainer,
    filterId,
    backgroundId,
  } = useAppSelector((state) => state.selfeApp);
  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  const refTarget = useRef<HTMLInputElement | null>(null);
  // const { second } = usePageTimer();
  const [stickerCarosel, setStickerCarousel] =
    useState<UseCarouselTypes | null>(null);

  const currentFilter = find(listFilterPhotos?.data, (o) =>
    isEqualVal(o?.id, filterId),
  );
  const isHasVideo =
    !shootingModeId || isEqualVal(shootingModeId, SHOOTING_TYPES.COUNTDOWN);

  const handleSelectSticker = (id: number, link: string) => () => {
    audio?.playClickToSelect?.();
    const NumberIdSimilar = map(
      filter(listSelectedSticker, (e) => e.stickerId === id),
      (e) => e.stickerId,
    );
    const newSticker = [
      ...listSelectedSticker,
      {
        stickerId: id,
        idUnique:
          listSelectedSticker.findIndex((e: any) => e.stickerId === id) !== -1
            ? `${id}-${NumberIdSimilar.length}`
            : id.toString(),
        image: link,
        width: 100,
        height: 100,
        rotate: 0,
        translate: [
          (photoContainer?.clientWidth || 0) / 2 - 50,
          (photoContainer?.clientHeight || 0) / 2 - 50,
        ],
      },
    ];
    dispatch(setSelfeAppStateAction({ listSelectedSticker: newSticker }));
  };

  const handleSelectCategory = (category: number | undefined) => () => {
    if (category) {
      audio?.playClickToSelect?.();
      stickerCarosel?.setActive?.(0);
      dispatch(setSelfeAppStateAction({ stickerCategoryId: category }));
    }
  };

  useEffect(() => {
    !stickerCategoryId &&
      dispatch(
        setSelfeAppStateAction({
          stickerCategoryId:
            listStickerCategoryPhotos?.data?.[0]?.id ||
            INIT_STICKER_CATEGORY_ID,
        }),
      );
  }, [dispatch, listStickerCategoryPhotos, stickerCategoryId]);

  // Số theme item trên 1 page
  const stickerPerPage = useMemo(
    () =>
      isVerticalScreen
        ? VERTICAL_SCREEN_ITEM_SELECTABLE_PER_PAGE
        : ITEM_SELECTABLE_PER_PAGE,
    [isVerticalScreen],
  );

  return (
    <>
      <div className="sticker-page">
        <div className="text-center sticker-page__sticker-category-carousel--wrapper">
          <Carousel
            hiddenDot
            hiddenBtn={false}
            slides={map(
              range(
                1,
                Math.ceil(
                  (listStickerCategoryPhotos?.totalRecord || 0) /
                    ITEM_CATEGORY_SELECTABLE_PER_PAGE,
                ) + 1,
              ),
              (_, ind) => (
                <div className="button-group">
                  {map(
                    slice(
                      listStickerCategoryPhotos?.data,
                      ind * ITEM_CATEGORY_SELECTABLE_PER_PAGE,
                      (ind + 1) * ITEM_CATEGORY_SELECTABLE_PER_PAGE,
                    ),
                    (category) => {
                      const isCateActive = isEqualVal(
                        stickerCategoryId || INIT_STICKER_CATEGORY_ID,
                        category?.id,
                      );
                      return (
                        <Button
                          key={category?.id}
                          color={isCateActive ? 'primary' : 'transparent'}
                          onClick={handleSelectCategory(category?.id)}
                          className={cx('category-button', {
                            'category-button-active': isCateActive,
                          })}
                          TypographyProps={{
                            className: 'text-ellipsis overflow-hidden',
                          }}
                        >
                          {category?.name}
                        </Button>
                      );
                    },
                  )}
                </div>
              ),
            )}
          />
        </div>
        <div className="flex items-center justify-center sticker-page__selection-section">
          <div className="sticker-page__sticker-photo-sheet--wrapper flex flex-col">
            <PhotoSheet
              className="sticker-page__sticker-photo-sheet"
              forwardedRef={refTarget}
              isSelectSticker
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        backgroundId
                          ? `${PHOTO_WITH_BG_PREFIX}${pickedPhotos?.[ind]?.fileName}`
                          : pickedPhotos?.[ind]?.fileName,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
                image: themeDetail?.image || currentLayout?.image,
              }}
              filterValues={currentFilter?.colorMatrix}
            />
            {isHasVideo && (
              <Typography
                className="font-semibold mt-7 sticker-page__warning-text"
                variant={TYPOGRAPHY_VARIANTS.SMALL}
                firstCapCase
              >
                {t('page:warningSticker')}
              </Typography>
            )}
          </div>
          <div className="sticker-page__sticker-carousel--wrapper">
            <Carousel
              setCarousel={setStickerCarousel}
              className="sticker-page__sticker-carousel"
              slides={map(
                range(
                  1,
                  Math.ceil(
                    (listStickerPhotos?.totalRecord || 0) / stickerPerPage,
                  ) + 1,
                ),
                (_, ind) => (
                  <div className="sticker-group">
                    {map(
                      slice(
                        listStickerPhotos?.data,
                        ind * stickerPerPage,
                        (ind + 1) * stickerPerPage,
                      ),
                      (item) => (
                        <button
                          key={item?.id}
                          type="button"
                          className={cx('sticker-item')}
                          onClick={handleSelectSticker(
                            item?.id,
                            item?.imageUrl,
                          )}
                        >
                          <img
                            src={resourceUrl(item?.imageUrl)}
                            alt="sticker-img"
                          />
                        </button>
                      ),
                    )}
                  </div>
                ),
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectSticker;

import { useCallback, useMemo } from 'react';
import { FRAME_TO_PAYMENT_DIRECTLY, PHOTO_PAGE } from 'constants/photo.const';
import { BE_DEFINES } from 'constants/backend-defines.const';
import { isEqualVal } from 'helpers/string.helper';
import { find, findKey, reduce } from 'lodash';
import { LifeCycleStepModel } from 'models/app.model';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';

export const useNewPhotoLifeCycleStep = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { frameId, LifeCycleStep, isChoosePaymentMethod, isChooseQRCodeToPay } =
    useAppSelector((state) => state.selfeApp);
  const { boothInfo, isTurnOnPayment } = useAppSelector((s) => s.booth);

  const initLifeCycleApp = () => {
    const cycleStep: {
      [key: string]: PartialBy<LifeCycleStepModel, 'id' | 'route'>;
    } = {
      [PHOTO_PAGE.FRAME]: {},
      [PHOTO_PAGE.LAYOUT]: {},
      [PHOTO_PAGE.THEME]: {},
      [PHOTO_PAGE.PAYMENT]: {},
      [PHOTO_PAGE.SHOOTING_MODE]: {},
      [PHOTO_PAGE.SHOOTING]: {},
      [PHOTO_PAGE.PICK_PHOTO]: {},
      [PHOTO_PAGE.ADD_FILTER]: {},
      [PHOTO_PAGE.PRINT]: {},
    };
    dispatch(
      setSelfeAppStateAction({
        LifeCycleStep: reduce(
          LifeCycleStep,
          (result, value, key) => ({
            ...result,
            [key]: { ...value, ...cycleStep?.[key] },
          }),
          {},
        ),
      }),
    );
  };

  const getCurrentRouteByRouteId = useCallback(
    (routeId: string = '') => {
      if (routeId) {
        return LifeCycleStep?.[routeId];
      }
      return null;
    },
    [LifeCycleStep],
  );

  const getCurrentRouteByPathName = useCallback(
    (path: string = '') => {
      const routeId = findKey(LifeCycleStep, (o) =>
        isEqualVal(o?.route, path || pathname),
      );
      if (routeId) {
        return LifeCycleStep?.[routeId];
      }
      return null;
    },
    [LifeCycleStep, pathname],
  );

  const getStartNodePath = () =>
    find(LifeCycleStep, (o) => !!o?.isStartNode)?.route;

  const getNextPath = (path: string = ''): string => {
    const _pathname = path || pathname;
    const currentRoute = getCurrentRouteByPathName(_pathname);
    const nextRouter = getCurrentRouteByRouteId(currentRoute?.nextId);

    // Todo!: remove fixed turn off QRcode
    // Check các chức năng mới Qrcode
    if (nextRouter?.isConditionRoute) {
      if (
        isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
        isEqualVal(nextRouter?.id, PHOTO_PAGE.QR_CODE)
      ) {
        return getNextPath(nextRouter?.route);
      }
    }

    // nếu route tiếp theo phải check điều kiện
    if (nextRouter?.isConditionRoute) {
      if (
        // Nếu đã chọn "Phương thức thanh toán", next là PaymentMethod => skip luôn
        (isChoosePaymentMethod &&
          isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT_METHOD)) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", next là QR code => skip luôn
        (!isTurnOnPayment &&
          (isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT) ||
            isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
            isEqualVal(nextRouter?.id, PHOTO_PAGE.QR_CODE))) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", next là QR code => skip luôn
        (isChoosePaymentMethod &&
          !isChooseQRCodeToPay &&
          isEqualVal(nextRouter?.id, PHOTO_PAGE.QR_CODE)) ||
        // -----
        // Nếu booth không phải Arm Robot Booth, next là route ShootingPosition => skip luôn
        (!isEqualVal(boothInfo?.Type, BE_DEFINES.ROBOT_ARM_BOOTH) &&
          isEqualVal(nextRouter?.id, PHOTO_PAGE.SHOOTING_POSITION))
      ) {
        return getNextPath(nextRouter?.route);
      }
    }

    return nextRouter?.route || '';
  };

  const getPrevPath = (path: string = ''): string => {
    const _pathname = path || pathname;
    const currentRoute = getCurrentRouteByPathName(_pathname);
    const prevRouter = getCurrentRouteByRouteId(currentRoute?.prevId);

    // Todo!: remove fixed turn off QRcode
    // Check các chức năng mới Qrcode
    if (prevRouter?.isConditionRoute) {
      if (
        isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
        isEqualVal(prevRouter?.id, PHOTO_PAGE.QR_CODE)
      ) {
        return getPrevPath(prevRouter?.route);
      }
    }

    // Todo!: remove fixed turn off more 4 layouts
    if (
      isEqualVal(frameId, FRAME_TO_PAYMENT_DIRECTLY) &&
      isEqualVal(prevRouter?.id, PHOTO_PAGE.LAYOUT)
    ) {
      return getPrevPath(prevRouter?.route);
    }

    // nếu route tiếp theo phải check điều kiện
    if (prevRouter?.isConditionRoute) {
      if (
        // Nếu đã chọn "Phương thức thanh toán", prev là PaymentMethod => skip luôn
        (isChoosePaymentMethod &&
          isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT_METHOD)) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", next là QR code => skip luôn
        (!isTurnOnPayment &&
          (isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT) ||
            isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
            isEqualVal(prevRouter?.id, PHOTO_PAGE.QR_CODE))) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", prev là QR code => skip luôn
        (isChoosePaymentMethod &&
          !isChooseQRCodeToPay &&
          isEqualVal(prevRouter?.id, PHOTO_PAGE.QR_CODE)) ||
        // -----
        // Nếu booth không phải Arm Robot Booth, prev là route ShootingPosition => skip luôn
        (!isEqualVal(boothInfo?.Type, BE_DEFINES.ROBOT_ARM_BOOTH) &&
          isEqualVal(prevRouter?.id, PHOTO_PAGE.SHOOTING_POSITION))
      ) {
        return getPrevPath(prevRouter?.route);
      }
    }

    return prevRouter?.route || '';
  };

  const setLifeCyclePageById = useCallback(
    (id: string = '', data: Partial<LifeCycleStepModel> | any) => {
      if (id) {
        dispatch(
          setSelfeAppStateAction({
            LifeCycleStep: { ...LifeCycleStep, [id]: data },
          }),
        );
      }
    },
    [LifeCycleStep, dispatch],
  );

  const currentRoute = useMemo(
    () => getCurrentRouteByPathName(pathname),
    [getCurrentRouteByPathName, pathname],
  );

  return {
    getCurrentRouteByPathName,
    initLifeCycleApp,
    setLifeCyclePageById,
    getNextPath,
    getPrevPath,
    getStartNodePath,
    currentRoute,
  };
};

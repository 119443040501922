export const TYPOGRAPHY_VARIANTS = {
  H1: 'h1',
  H2: 'h2',
  BODY: 'body',
  SMALL: 'small',
  XS: 'xs',
};

export const TYPOGRAPHY_COMPONENT = {
  STRONG: 'strong',
  DIV: 'div',
  PARAGRAPH: 'p',
  LABEL: 'label',
  SPAN: 'span',
};

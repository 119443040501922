import qs from 'query-string';
import { map } from 'lodash';
import {
  ListBackgroundCategoryPhotoRequestModel,
  ListBackgroundCategoryPhotoResponseModel,
  BackgroundCategoryPhotoStateModel,
} from 'models/photo/background-category.model';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';

export const backgroundCategoryApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listBackgroundCategoryApiAction: build.query<
      BackgroundCategoryPhotoStateModel['listBackgroundCategoryPhotos'],
      ListBackgroundCategoryPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/background-categories/getlist?${qs.stringify(
          payload,
        )}`,
      }),
      providesTags: (data) => [
        ...map(
          data?.data,
          ({ id }) => ({ type: 'ThemeCategoryPhoto', id } as const),
        ),
        { type: 'ThemeCategoryPhoto' as const, id: 'LIST' },
      ],
      transformResponse: (
        rawResult: ListBackgroundCategoryPhotoResponseModel,
      ) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const {
  useListBackgroundCategoryApiActionQuery,
  useLazyListBackgroundCategoryApiActionQuery,
} = backgroundCategoryApi;

export const {
  endpoints: { listBackgroundCategoryApiAction },
} = backgroundCategoryApi;

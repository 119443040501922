import React, { HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { AssetImage } from 'assets/v2/images/AssetImage';
import { resourceUrl } from 'helpers/url.helper';
import { useAppSelector } from 'store/store-hooks';
import './title-and-logo.css';

export type Props = HtmlHTMLAttributes<HTMLDivElement> & {
  text?: string | null;
  hideTitle?: boolean;
};

const TitleAndLogo: React.FC<Props> = ({
  text,
  className,
  hideTitle = false,
  ...rest
}) => {
  const { boothInfo } = useAppSelector((state) => state.booth);

  return (
    <div {...rest} className={cx('title-and-logo', className)}>
      {!hideTitle && (
        <Typography
          variant={TYPOGRAPHY_VARIANTS.H1}
          data-text={text}
          className="page-title"
        >
          {text}
        </Typography>
      )}
      {boothInfo?.VerticalTitleImageUrl && (
        <img
          className="title-and-logo__start-mascot-img"
          src={resourceUrl(boothInfo?.VerticalTitleImageUrl)}
          alt=""
        />
      )}
      <img className="title-and-logo__logo" src={AssetImage.WhiteLogo} alt="" />
    </div>
  );
};

export default TitleAndLogo;

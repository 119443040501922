import React from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { formatMoneyVN } from 'helpers/math.helper';
import { CURRENCY_SYMBOL } from 'constants/photo.const';
import girlEyeBlink from 'assets/images/girl_eye_blink.png';
import './photo-guide.css';
import { Icons } from 'assets/icon/Icons';

function PhotoGuide2() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);

  return (
    <div className="photo-guide">
      <Typography
        variant={TYPOGRAPHY_VARIANTS.H1}
        className="uppercase text-center font-black photo-guide-title"
      >
        {t('page:photoGuide')}
      </Typography>
      <div className="flex items-center justify-center">
        <div className="photo-guide-card">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="text-center font-extrabold photo-guide-card-title"
          >
            <Icons.CircleDollar /> {t('page:photoCost')}
          </Typography>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="font-bold group-info-wrapper-title"
            >
              {t('page:frame26Inch')}:
            </Typography>
            <ul className="group-info-list">
              <li className="group-info-item">
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="support-text"
                >
                  {t('page:layout4photo')}:
                </Typography>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="font-bold group-info-item-value"
                >
                  {formatMoneyVN(60000)}
                  {CURRENCY_SYMBOL}
                </Typography>
              </li>
            </ul>
          </div>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="font-bold group-info-wrapper-title"
            >
              {t('page:frame46Inch')}:
            </Typography>
            <ul className="group-info-list">
              <li className="group-info-item">
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="support-text"
                >
                  {t('page:layout1photo')}:
                </Typography>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="font-bold group-info-item-value"
                >
                  {formatMoneyVN(60000)}
                  {CURRENCY_SYMBOL}
                </Typography>
              </li>
              <li className="group-info-item">
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="support-text"
                >
                  {t('page:layout3photo')}:
                </Typography>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="font-bold group-info-item-value"
                >
                  {formatMoneyVN(60000)}
                  {CURRENCY_SYMBOL}
                </Typography>
              </li>
              <li className="group-info-item">
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="support-text"
                >
                  {t('page:layout4photo')}:
                </Typography>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="font-bold group-info-item-value"
                >
                  {formatMoneyVN(60000)}
                  {CURRENCY_SYMBOL}
                </Typography>
              </li>
              <li className="group-info-item">
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="support-text"
                >
                  {t('page:layout6photo')}:
                </Typography>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="font-bold group-info-item-value"
                >
                  {formatMoneyVN(70000)}
                  {CURRENCY_SYMBOL}
                </Typography>
              </li>
              <li className="group-info-item">
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="support-text"
                >
                  {t('page:layout8photo')}:
                </Typography>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  className="font-bold group-info-item-value"
                >
                  {formatMoneyVN(70000)}
                  {CURRENCY_SYMBOL}
                </Typography>
              </li>
            </ul>
          </div>
          <div className="info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="font-normal info-title"
            >
              * {t('page:include1_46inch')}
            </Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="font-normal info-title"
            >
              * {t('page:freeEquipment')}
            </Typography>
          </div>
          <Typography
            variant={TYPOGRAPHY_VARIANTS.SMALL}
            className="font-normal bg-info-title"
          >
            {t('page:46inch_2_26inch')}
          </Typography>
        </div>
        <div className="photo-guide-card">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="text-center font-extrabold photo-guide-card-title"
          >
            <Icons.CameraReplace /> {t('page:payment')}
          </Typography>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="font-bold group-info-wrapper-title"
            >
              {t('page:printAmount')}
            </Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="group-info-wrapper-description"
            >
              {t('page:printMoreWithCost')}
            </Typography>
          </div>
          <div className="group-info-wrapper">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="font-bold group-info-wrapper-title"
            >
              {t('page:frame46Inch')}:
            </Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="group-info-wrapper-description"
            >
              {t('page:acceptMoney')}
            </Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.SMALL}
              className="group-info-wrapper-description"
            >
              {t('page:notRefundMoney')}
            </Typography>
            <img
              src={girlEyeBlink}
              alt="girlEyeBlink"
              className="img-girl-blink"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGuide2;

import seq from 'seq-logging';
import CONFIG from 'config';
import { LoggingQueueModel } from 'models/common.model';
import moment from 'moment';
import {
  LOGGING_LEVEL,
  LOG_STATE_EXCEPT,
  LOG_PROPERTIES,
} from 'constants/logging.const';
import { omit } from 'lodash';
import { store } from 'store';

const logger = new seq.Logger({
  serverUrl: CONFIG.REACT_APP_SEQ_LOGGING_URL || '',
  apiKey: CONFIG.REACT_APP_SEQ_LOGGING_API_KEY || '',
  // eslint-disable-next-line no-console
  onError: (err) => console.log('>>> Error: ', err),
});

export const emitSeqLog = (data: Partial<LoggingQueueModel>) => {
  if (data) {
    logger.emit({
      ...data,
      timestamp: moment().toDate(),
      level: data?.level || LOGGING_LEVEL.INFORMATION,
      messageTemplate: `[FE]: ${data?.messageTemplate}`,
      properties: {
        ...LOG_PROPERTIES,
        Environment: process.env.NODE_ENV,
        BoothId: store.getState()?.booth?.boothId,
        AppState: omit(store.getState()?.selfeApp, LOG_STATE_EXCEPT),
        ...data?.properties,
      },
    });
  }
};

import {
  clone,
  compact,
  concat,
  filter,
  findIndex,
  includes,
  map,
  range,
  reduce,
  size,
} from 'lodash';
import { PickedPhotosModel } from '../models/common.model';
import { isEqualVal } from './string.helper';

/**
 * Lấy danh sách ở tham số 1 và không thuộc tham số 2
 *
 * @category array
 * @param string[] types to compare
 * @param string[] types to compare
 * @returns Returns string[].
 * @example
 *
 * getRemainList(["abc", "cbvaa"], ["abc"]) => ["abc"]
 * getRemainList(["abc123", "cbvaa"], ["abc", "cbvaa"]) => ["abc123"]
 *
 */
function getRemainList(srcArr: string[] | undefined, rvArr: string[]) {
  return filter(srcArr, (o) => !includes(rvArr, o));
}

/**
 * Tự động làm đầy danh sách
 *
 * @category array
 * @param string[]
 * @param string[]
 * @param number
 * @returns Returns string[].
 * @example
 *
 * getAutoFillList(["a", "b", "c", "d"], ["a", "", "c"], 3) => ["a", "b", "c"]
 * getAutoFillList(["a", "ddd", "c", "bbb"], ["a", "", "c"], 3) => ["a", "ddd", "c"]
 * getAutoFillList(["a", "ddd", "c", "bbb"], ["a", "", "c"], 2) => ["a", "ddd"]
 *
 */
function getAutoFillList(
  listAll: string[],
  listOfSelected: (PickedPhotosModel | null)[],
  maxQuantity: number,
): (PickedPhotosModel | null)[] {
  if (size(compact(listOfSelected)) === maxQuantity) {
    return clone(listOfSelected);
  }
  return reduce(
    range(maxQuantity),
    (results: (PickedPhotosModel | null)[], num: number) => {
      const notSelectedYet = filter(
        map(listAll, (i) => ({
          fileName: i,
          rotate: 0,
          flip: null,
        })),
        (o) =>
          !includes(
            map(concat(results, listOfSelected), 'fileName'),
            o?.fileName,
          ),
      );
      return [
        ...results,
        listOfSelected?.[num] ? listOfSelected?.[num] : notSelectedYet?.[0],
      ];
    },
    [],
  );
}

/**
 * Sắp xếp số tăng dần
 *
 * @category array
 * @param number[]
 * @returns Returns number[].
 * @example
 *
 */
function sortAscListOfNum(numArr: number[]) {
  const tempArrNum = clone(numArr);

  tempArrNum.sort((a, b) => a - b);

  return tempArrNum;
}

/**
 * Tự động điền vào chỗ trống
 *
 * @category array
 * @param string[]
 * @param string
 * @returns Returns string[].
 * @example
 *
 * getAutoFillList(["a", "", "c", "d"], "b") => ["a", "b", "c", "d"]
 *
 * getAutoFillList(["a", "c", "d"], "b") => ["a", "c", "d", "b"]
 *
 */
function putToArrayHasEmptySlot(
  list: (PickedPhotosModel | null)[] = [],
  itemToPut: string = '',
) {
  const newItem = {
    fileName: itemToPut,
    rotate: 0,
    flip: null,
  };

  if (!size(compact(list))) return [newItem];

  if (size(compact(list)) !== size(list)) {
    const indexOfEmptySlot = findIndex(list, (i) => !i?.fileName);
    if (indexOfEmptySlot >= 0) {
      return map(list, (i, ind) => {
        if (isEqualVal(ind, indexOfEmptySlot)) return newItem;
        return i;
      });
    }
  }

  return concat(list, newItem);
}

function includesId(
  array: (string | number)[],
  id: string | number | null | undefined,
): boolean {
  return !!size(filter(array, (o) => isEqualVal(o, id)));
}

export {
  getRemainList,
  getAutoFillList,
  sortAscListOfNum,
  putToArrayHasEmptySlot,
  includesId,
};

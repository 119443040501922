import { attempt, isError } from 'lodash';

/**
 * Trả ra giá trị mặc định nếu param1 (0, null, undefined, false hoặc "")
 *
 * @category all types
 * @param any
 * @param any
 * @returns Returns default value any.
 * @example
 *
 */
function dv(val: any, dVal: any = null) {
  return val || dVal;
}

/**
 * same JSON parse
 */
function jsonParse(str: string, defaultValue = {}) {
  const result = attempt(JSON.parse.bind(null, str));
  if (isError(result) || typeof result != 'object') {
    return defaultValue;
  }

  return result;
}

function getLocalStorage(keyName: string): string {
  if (!window.localStorage) return '';

  return window.localStorage.getItem(keyName) || '';
}

function setLocalStorage(keyName: string, data: any | number | string) {
  if (!window.localStorage) return;

  if (typeof data === 'string' || typeof data === 'number') {
    window.localStorage.setItem(keyName, `${data}`);
    return;
  }

  window.localStorage.setItem(keyName, JSON.stringify(data));
}

function getSessionStorage(keyName: string): string {
  if (!window.sessionStorage) return '';

  return window.sessionStorage.getItem(keyName) || '';
}

function setSessionStorage(keyName: string, data: any | number | string) {
  if (!window.sessionStorage) return;

  if (typeof data === 'string' || typeof data === 'number') {
    window.sessionStorage.setItem(keyName, `${data}`);
    return;
  }

  window.sessionStorage.setItem(keyName, JSON.stringify(data));
}

async function loadCachedImage(url: string) {
  if (!window.sessionStorage) return;

  const cachedImage = window.sessionStorage.getItem(url);
  if (cachedImage) {
    return cachedImage;
  }

  const response = await fetch(url);
  const blob = await response.blob();
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onloadend = () => {
      window.sessionStorage.setItem(url, reader.result as any);
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

export {
  dv,
  jsonParse,
  getLocalStorage,
  setLocalStorage,
  getSessionStorage,
  setSessionStorage,
  loadCachedImage,
};

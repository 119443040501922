import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import { clone, compact, findIndex, includes, map, range, size } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import {
  PICK_PHOTO_GRID_SIZE,
  PICK_PHOTO_VERTICAL_SCREEN_COL_SIZE,
  PICK_PHOTO_VERTICAL_SCREEN_ROW_SIZE,
} from 'constants/photo.const';
import { STRAIGHT_ANGLE } from 'constants/common.const';
import { processedImageUrl } from 'helpers/url.helper';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useNavigate } from 'react-router-dom';
import PageActions from 'components/page-actions/PageActions';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import { getAutoFillList, putToArrayHasEmptySlot } from 'helpers/array.helper';
import { isEqualVal } from 'helpers/string.helper';
import { useSoundContext } from 'context/SoundContext';
import { useBoothAppContext } from 'context/BoothAppContext';
import { usePageTimer } from 'hooks/usePageTimer';
import { setTrackingStateAction } from 'store/features/app/trackingSlice';
import RotateIcon from 'assets/icon/rotate.png';
import FlipIcon from 'assets/icon/flip.png';
import './pick-photo.css';

function PickPhoto() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { listPhotoTakens, currentLayout } = useBoothAppContext();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const [imageHash, setImageHash] = useState(Date.now());
  const { transactionId, quantityPicOfPerSheet, pickedPhotos } = useAppSelector(
    (state) => state.selfeApp,
  );
  const { isRotatablePhoto, isVerticalScreen } = useAppSelector((s) => s.booth);

  // timeup => auto select photo, redirect next page
  const { second } = usePageTimer({
    forwardFunc: () => {
      dispatch(
        setSelfeAppStateAction({
          pickedPhotos: getAutoFillList(
            listPhotoTakens?.data || [],
            pickedPhotos,
            quantityPicOfPerSheet,
          ),
        }),
      );
      handleNext?.();
    },
  });
  const handleRotate = (img: string) => {
    audio?.playClickToSelect?.();
    dispatch(
      setSelfeAppStateAction({
        pickedPhotos: clone(
          map(pickedPhotos, (i) => {
            if (isEqualVal(i?.fileName || '', img) && i) {
              return { ...i, rotate: !i?.rotate ? STRAIGHT_ANGLE : 0 };
            }
            return i || null;
          }),
        ),
      }),
    );
  };

  const handleFlip = (img: string) => {
    audio?.playClickToSelect?.();
    dispatch(
      setSelfeAppStateAction({
        pickedPhotos: clone(
          map(pickedPhotos, (i) => {
            if (i && isEqualVal(i?.fileName, img)) {
              return { ...i, flip: i?.flip ? null : -1 };
            }
            return i;
          }),
        ),
      }),
    );
  };

  const handleClickPhoto = (img: string) => () => {
    const isExist = includes(
      map(pickedPhotos, (i) => i?.fileName),
      img,
    );
    setImageHash(Date.now());
    if (isExist || size(compact(pickedPhotos)) < quantityPicOfPerSheet) {
      const newListSelected = isExist
        ? map(pickedPhotos, (i) =>
            !isEqualVal(i?.fileName || '', img) ? i : null,
          )
        : putToArrayHasEmptySlot(pickedPhotos, img);
      dispatch(
        setSelfeAppStateAction({ pickedPhotos: clone(newListSelected) }),
      );

      audio?.playClickToSelect?.();
    }
  };

  const handleRemovePhoto = (index: number) => {
    audio?.playClickToSelect?.();
    setImageHash(Date.now());
    dispatch(
      setSelfeAppStateAction({
        pickedPhotos: map(pickedPhotos, (img, i) =>
          !isEqualVal(i, index) ? img : null,
        ),
      }),
    );
  };

  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  // tracking spend time
  useEffect(() => {
    dispatch(setTrackingStateAction({ LastStartTimeEditPhoto: new Date() }));
  }, [dispatch]);

  const renderPickPhotoActions = () => (
    <PageActions
      hidePrev
      NextButtonProps={{
        children: t('common:continue'),
        disabled: size(compact(pickedPhotos)) < quantityPicOfPerSheet,
      }}
      handleContinue={handleNext}
      className={cx({ 'pick-photo__page-actions--vertical': isVerticalScreen })}
    />
  );

  const { rowSize, colSize } = useMemo(
    () => ({
      rowSize: isVerticalScreen
        ? PICK_PHOTO_VERTICAL_SCREEN_ROW_SIZE
        : PICK_PHOTO_GRID_SIZE,
      colSize: isVerticalScreen
        ? PICK_PHOTO_VERTICAL_SCREEN_COL_SIZE
        : PICK_PHOTO_GRID_SIZE,
    }),
    [isVerticalScreen],
  );

  return (
    <>
      <div className="pick-photo">
        <TimerText second={second} />
        {!isVerticalScreen && (
          <div className="page-title-margin pick-photo-title">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={t(`${I18nNamespace.COMMON}:pickPhoto`)}
              className="page-title"
            >
              {t(`${I18nNamespace.COMMON}:pickPhoto`)}
            </Typography>
          </div>
        )}
        {isVerticalScreen && renderPickPhotoActions()}
        <div className="pick-photo__section">
          <div className="pick-photo__image-frame transition-img">
            <PhotoSheet
              className="pick-photo__example-img"
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        pickedPhotos?.[ind]?.fileName,
                        imageHash,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
              }}
              imageClick={handleRemovePhoto}
              showPhotoIndex
              isSelectSticker={false}
            />
          </div>
          <div className="pick-photo__img-grid">
            {map(range(rowSize), (__, index) => (
              <div className="pick-photo__img-row">
                {map(range(colSize), (_, ind) => {
                  const photoFileName =
                    listPhotoTakens?.data?.[index * colSize + ind];

                  if (!photoFileName) return null;

                  const assetUrl = photoFileName
                    ? processedImageUrl(transactionId, photoFileName, imageHash)
                    : '';
                  const thumbnailHeight =
                    (currentLayout?.ratioX || 1) > (currentLayout?.ratioY || 1)
                      ? { width: isVerticalScreen ? `20rem` : '24rem' }
                      : { width: isVerticalScreen ? `11.5rem` : '18rem' };

                  const pickedPhotoIndex = findIndex(pickedPhotos, (o) =>
                    isEqualVal(o?.fileName, photoFileName),
                  );
                  const isPickedPhoto = pickedPhotoIndex >= 0;

                  return (
                    <button
                      type="button"
                      className={cx('pick-photo__img-pick relative', {
                        active: isPickedPhoto,
                      })}
                      onClick={handleClickPhoto(photoFileName)}
                      style={{
                        aspectRatio: `${currentLayout?.ratioX || 1} / ${
                          currentLayout?.ratioY || 1
                        }`,
                        ...thumbnailHeight,
                      }}
                    >
                      <img src={assetUrl} alt="pick-img" className="no-drag" />
                      {isPickedPhoto && (
                        <div className="pick-photo__img-order-no">
                          <Typography
                            className="font-black pick-photo__img-order-no-text"
                            variant={TYPOGRAPHY_VARIANTS.XS}
                          >
                            {pickedPhotoIndex + 1}
                          </Typography>
                        </div>
                      )}
                      {isRotatablePhoto && isPickedPhoto && (
                        <div className={`pick-photo__btn-rotate-actions`}>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRotate(photoFileName);
                            }}
                          >
                            <img src={RotateIcon} alt="icon_Rotate" />
                          </button>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFlip(photoFileName);
                            }}
                          >
                            <img src={FlipIcon} alt="icon_Flip" />
                          </button>
                        </div>
                      )}
                    </button>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPickPhotoActions()}
      {isVerticalScreen && (
        <TitleAndLogo
          className="pick-photo__title"
          text={t(`${I18nNamespace.COMMON}:pickPhoto`)}
        />
      )}
    </>
  );
}

export default PickPhoto;

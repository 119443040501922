import { loadCachedImage } from 'helpers/common.helper';
import React, { useState, useEffect, ImgHTMLAttributes } from 'react';

export type CachedImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  imageUrl: ImgHTMLAttributes<HTMLImageElement>['src'];
};

const CachedImage: React.FC<CachedImageProps> = ({
  imageUrl = '',
  ...rest
}) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    loadCachedImage(imageUrl).then((dataUrl: any) => {
      setSrc(dataUrl || '');
    });
  }, [imageUrl]);

  return <img {...rest} alt={rest?.alt || 'Cached'} src={src} />;
};

export default CachedImage;

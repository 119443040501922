export const BUTTON_COLORS = {
  PRIMARY: 'primary',
  DEFAULT: 'default',
  TRANSPARENT: 'transparent',
};

export enum PhotoFilterTypes {
  NONE = 'none',
  BLACK_WHITE = 'black-white',
  WARM = 'warm',
  COOL = 'cool',
  VINTAGE = 'vintage',
  GRITTY = 'gritty',
  HAPPY = 'happy',
  CINEMATIC = 'cinematic',
}

export const OFFSET_PER = 0.4;

export const FILTER_MATRIX = {
  [PhotoFilterTypes.NONE]: [
    1, 0, 0, 0, 0,
    //
    0, 1, 0, 0, 0,
    //
    0, 0, 1, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
  [PhotoFilterTypes.BLACK_WHITE]: [
    0.299, 0.587, 0.114, 0, 0,
    //
    0.299, 0.587, 0.114, 0, 0,
    //
    0.299, 0.587, 0.114, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
  [PhotoFilterTypes.WARM]: [
    1.2, 0, 0, 0, 0,
    //
    0, 1.1, 0, 0, 0,
    //
    0, 0, 1, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
  [PhotoFilterTypes.COOL]: [
    1, 0, 0, 0, -0.1,
    //
    0, 1.2, 0, 0, -0.1,
    //
    0, 0, 1.6, 0, -0.1,
    //
    0, 0, 0, 1, -0.1,
  ],
  [PhotoFilterTypes.VINTAGE]: [
    0.393, 0.769, 0.189, 0, 0,
    //
    0.349, 0.686, 0.168, 0, 0,
    //
    0.272, 0.534, 0.131, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
  [PhotoFilterTypes.GRITTY]: [
    1.5, 1.5, 1.5, 0, 0,
    //
    1.5, 1.5, 1.5, 0, 0,
    //
    1.5, 1.5, 1.5, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
  [PhotoFilterTypes.HAPPY]: [
    1.2, 0, 0, 0, 0,
    //
    0, 1.2, 0, 0, 0,
    //
    0, 0, 1.2, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
  [PhotoFilterTypes.CINEMATIC]: [
    1.5, 0, 0, 0, 0,
    //
    0, 1.2, 0, 0, 0,
    //
    0, 0, 0.8, 0, 0,
    //
    0, 0, 0, 1, 0,
  ],
};

import {
  GetRobotArmPositionRequestModel,
  GetRobotArmPositionResponseModel,
  GetRobotArmStatusRequestModel,
  GetRobotArmStatusResponseModel,
  GoToRobotArmHomePositionRequestModel,
  RobotArmStateModel,
  UpdateRobotArmPoseRequestModel,
  UpdateRobotArmPositionRequestModel,
} from 'models/photo/robot-arm.model';
import { PREFIX_API } from 'constants/api.const';
import { robotArmBaseSliceApi } from './base.slice-api';

export const robotArmApi = robotArmBaseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    goToRobotArmHomePositionApiAction: build.mutation<
      boolean,
      GoToRobotArmHomePositionRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/homing`,
        method: 'PUT',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    getRobotArmStatusApiAction: build.query<
      RobotArmStateModel['robotArmStatus'] | null,
      GetRobotArmStatusRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/status`,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: (rawResult: GetRobotArmStatusResponseModel) =>
        rawResult ?? null,
    }),
    getRobotArmPositionApiAction: build.query<
      RobotArmStateModel['robotArmPosition'] | null,
      GetRobotArmPositionRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/position`,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: (rawResult: GetRobotArmPositionResponseModel) =>
        rawResult ?? null,
    }),
    updateRobotArmPositionApiAction: build.mutation<
      RobotArmStateModel['robotArmPosition'],
      UpdateRobotArmPositionRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/position?speed=10`,
        method: 'PUT',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    updateRobotArmPoseApiAction: build.mutation<
      RobotArmStateModel['robotArmPosition'],
      UpdateRobotArmPoseRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/pose?speed=10`,
        method: 'PUT',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useGetRobotArmStatusApiActionQuery,
  useLazyGetRobotArmStatusApiActionQuery,
  useLazyGetRobotArmPositionApiActionQuery,
  useGoToRobotArmHomePositionApiActionMutation,
  useUpdateRobotArmPositionApiActionMutation,
  useUpdateRobotArmPoseApiActionMutation,
} = robotArmApi;

export const {
  endpoints: { getRobotArmStatusApiAction, updateRobotArmPositionApiAction },
} = robotArmApi;

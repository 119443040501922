import qs from 'query-string';
import { map } from 'lodash';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';
import {
  ListStickerCategoryPhotoRequestModel,
  ListStickerCategoryPhotoResponseModel,
  StickerCategoryPhotoStateModel,
} from '../../models/photo/sticker-category.model';

export const stickerCategoryApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listStickerCategoryApiAction: build.query<
      StickerCategoryPhotoStateModel['listStickerCategoryPhotos'],
      ListStickerCategoryPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/sticker-categories/getlist?${qs.stringify(
          payload,
        )}`,
      }),
      providesTags: (data) => [
        ...map(
          data?.data,
          ({ id }) => ({ type: 'StickerCategoryPhoto', id } as const),
        ),
        { type: 'StickerCategoryPhoto' as const, id: 'LIST' },
      ],
      transformResponse: (
        rawResult: ListStickerCategoryPhotoResponseModel,
      ) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const {
  useListStickerCategoryApiActionQuery,
  useLazyListStickerCategoryApiActionQuery,
} = stickerCategoryApi;

export const {
  endpoints: { listStickerCategoryApiAction },
} = stickerCategoryApi;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ButtonHTMLAttributes,
  FC,
  HtmlHTMLAttributes,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import cx from 'classnames';
import { FRAME_TIME_DURATION } from 'constants/photo.const';
import { TFunction } from 'i18next';
import { MouseEventDiv } from 'models/dom-element.model';
import PopoverBox, { PopoverBoxProps } from './PopoverBox';
import './popover.css';

export type PopoverProps = HtmlHTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  content?: ReactElement | string | TFunction;
  hideClose?: boolean;
  RenderContent?: () => ReactElement | string;
  onClose?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  onOk?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  PopoverBoxProps?: PopoverBoxProps;
  ToggleProps?: HtmlHTMLAttributes<HTMLDivElement>;

  isUseTimer?: boolean;
  duration?: number;
  onTimeUp?: () => void;
};

const Popover: FC<PopoverProps> = ({
  open = false,
  content = '',
  hideClose = false,
  RenderContent = null,
  onClose,
  onOk,
  PopoverBoxProps,
  ToggleProps,

  isUseTimer = false,
  onTimeUp,
  duration = FRAME_TIME_DURATION,

  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const show = useCallback((e: MouseEventDiv) => {
    e?.preventDefault();
    e?.stopPropagation();
    setAnchorEl(e?.currentTarget);
  }, []);

  const open1 = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div
        aria-describedby={id}
        aria-owns={id}
        aria-haspopup="true"
        onClick={show}
        aria-hidden
        {...ToggleProps}
        className={cx('pb-popover-toggle', ToggleProps?.className)}
      >
        {RenderContent && RenderContent()}
      </div>
      <PopoverBox
        {...PopoverBoxProps}
        open={open1}
        anchorDimension={anchorEl?.getBoundingClientRect?.()}
        content={content}
      />
    </>
  );
};

export default Popover;

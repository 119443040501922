import React, { FC } from 'react';
import './popup.css';
import { useModal } from 'hooks/useModal';
import Popup, { PopupProps } from './Popup';

export type PopupConfirmProps = PopupProps & {};

const PopupConfirm: FC<PopupConfirmProps> = ({ ...rest }) => {
  const { closeConfirmModal } = useModal();

  return <Popup onClose={closeConfirmModal} hideClose {...rest} />;
};

export default PopupConfirm;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FilterPhotoStateModel } from 'models/photo/filter.model';

// Define the initial state using that type
const initialState: FilterPhotoStateModel = {
  listFilterPhotos: {
    data: [],
    totalRecord: 0,
  },
  filterPhotoDetail: null,
  loadingInList: false,
};

export const filterPhotoSlice = createSlice({
  name: 'filterPhoto',
  initialState,
  reducers: {
    setFilterPhotoStateAction: (
      state,
      action: PayloadAction<Partial<FilterPhotoStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
});

export const { setFilterPhotoStateAction } = filterPhotoSlice.actions;

export default filterPhotoSlice.reducer;

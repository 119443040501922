import qs from 'query-string';
import { map } from 'lodash';
import {
  GetThemeDetailPhotoRequestModel,
  GetThemeDetailPhotoResponseModel,
  ListThemeByCategoryPhotoRequestModel,
  ListThemeByCategoryPhotoResponseModel,
  ListThemePhotoRequestModel,
  ListThemePhotoResponseModel,
  ThemeDetailPhotoModel,
  ThemePhotoStateModel,
} from 'models/photo/theme.model';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';

export const themeApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listThemeApiAction: build.query<
      ThemePhotoStateModel['listThemePhotos'],
      ListThemePhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/theme/getbylayoutid?${qs.stringify(payload)}`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'ThemePhoto', id } as const)),
        { type: 'ThemePhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: ListThemePhotoResponseModel) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getThemeDetailApiAction: build.query<
      ThemeDetailPhotoModel | null,
      GetThemeDetailPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/theme/getdetailbythemeid?${qs.stringify(
          payload,
        )}`,
      }),
      providesTags: (data) => [
        { type: 'ThemeDetailPhoto' as const, id: data?.id },
      ],
      transformResponse: (rawResult: GetThemeDetailPhotoResponseModel) =>
        rawResult?.response ?? null,
      extraOptions: { maxRetries: 1 },
    }),
    listThemeByCategoryApiAction: build.query<
      ThemePhotoStateModel['listThemePhotos'],
      ListThemeByCategoryPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/theme/getlist?${qs.stringify(payload)}`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'ThemePhoto', id } as const)),
        { type: 'ThemePhoto' as const, id: 'LIST' },
      ],
      transformResponse: (
        rawResult: ListThemeByCategoryPhotoResponseModel,
      ) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const {
  useListThemeApiActionQuery,
  useLazyListThemeApiActionQuery,
  useGetThemeDetailApiActionQuery,
  useLazyGetThemeDetailApiActionQuery,
  useListThemeByCategoryApiActionQuery,
  useLazyListThemeByCategoryApiActionQuery,
} = themeApi;

export const {
  endpoints: { listThemeApiAction },
} = themeApi;

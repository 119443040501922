import React, { FC, PropsWithChildren, useContext, useEffect } from 'react';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { LOGGING_LEVEL } from 'constants/logging.const';
import { TIME_DEBOUNCE_TAKE_PHOTO } from 'constants/photo.const';
import { emitSeqLog } from 'functions/seq-logging.func';

export const Context = React.createContext<LoggingContextReturnTypes>({});

interface LoggingContextProps {}
type LoggingContextReturnTypes = {};

export const LoggingContext: FC<PropsWithChildren<LoggingContextProps>> = ({
  children,
}) => {
  const handleEventError = useDebouncedCallback(
    (e) => {
      emitSeqLog({
        level: LOGGING_LEVEL.ERROR,
        messageTemplate: `[Path: ${window?.location?.pathname}] JavaScript error: {message}`,
        properties: {
          type: e?.type,
          message: e?.message,
        },
      });
    },
    [],
    TIME_DEBOUNCE_TAKE_PHOTO,
    { trailing: false, leading: true },
  );

  useEffect(() => {
    window.addEventListener('error', handleEventError);
    return () => {
      document.removeEventListener('keyup', handleEventError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Context.Provider value={{}}>{children}</Context.Provider>;
};

export const useLoggingContext = () => useContext(Context);

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import TimerText from 'components/timer-text/TimerText';
import Typography from 'components/typography/Typography';
import CustomRoutes from 'components/custom-routes/CustomRoutes';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import PageActions from 'components/page-actions/PageActions';
import { I18nNamespace } from 'constants/i18n.const';
import {
  FRAME_TO_PAYMENT_DIRECTLY,
  SHOOTING_TYPES,
  TIME_DELAY_PROCESS_VIDEO,
  TIME_TO_DELAY_REDIRECT_PRINT,
} from 'constants/photo.const';
import { SelfeRouteConst } from 'constants/route.const';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import { isEqualVal } from 'helpers/string.helper';
import { diffFromDate } from 'helpers/time.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useRouter } from 'hooks/useRouter';
import { delay, filter, find, map } from 'lodash';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import { SelfeAppStateModel } from 'models/app.model';
import { LayoutPhotoModel } from 'models/photo/layout.model';
import { TrackingStateModel } from 'models/photo/tracking.model';
import {
  useProcessPhotoTakenApiActionMutation,
  useProcessVideoTakenApiActionMutation,
} from 'store/api/photo-taken.slice-api';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { BoothStateModel } from '../../../models/photo/booth.model';
import SelectBackground from '../select-background/Select-background';
import SelectTheme from '../select-theme/SelectTheme';
import SelectSticker from '../select-sticker/Select-sticker';
import AddFilter from '../add-filter/AddFilter';
import { useModal } from '../../../hooks/useModal';
import './edit-photo.css';

const EditPhoto = () => {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const { audio } = useSoundContext();
  const { isMatchPath } = useRouter();
  const navigate = useNavigate();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const dispatch = useAppDispatch();
  const [processPhotoTaken] = useProcessPhotoTakenApiActionMutation();
  const [processVideoTaken] = useProcessVideoTakenApiActionMutation();
  const { second, resetTime } = usePageTimer({
    forwardFunc: () => handleNext?.(),
  });
  const { openConfirmModal, closeConfirmModal } = useModal();

  const {
    frameId,
    layoutId,
    themeId,
    themeDetailId,
    depositAmount,
    quantitySheet,
    appliedVoucherCode,
    appliedVoucherValue,
    shootingModeId,
    filterId,
    transactionId,
    pickedPhotos,
    listSelectedSticker,
    photoContainer,
    backgroundId,
  } = useAppSelector(
    (state: { selfeApp: SelfeAppStateModel }) => state.selfeApp,
  );
  const { currentLayout } = useBoothAppContext();
  const { isTurnOnSticker, isTurnOnBackground, isVerticalScreen } =
    useAppSelector((state: { booth: BoothStateModel }) => state.booth);
  const {
    LastStartTimePurchase,
    LastStartTimeTakePhoto,
    LastStartTimeEditPhoto,
  } = useAppSelector(
    (state: { tracking: TrackingStateModel }) => state.tracking,
  );

  const calculateSizeAfterRotation = (w: number, h: number, deg: number) => {
    const radians = (deg * Math.PI) / 180;

    // Calculate new width and height
    const cos = Math.abs(Math.cos(radians));
    const sin = Math.abs(Math.sin(radians));
    const newWidth = w * cos + h * sin;
    const newHeight = w * sin + h * cos;

    return { width: Math.round(newWidth), height: Math.round(newHeight) };
  };

  const calculateCoordinatesAfterRotation = (
    originalX: number,
    originalY: number,
    originW: number,
    originH: number,
    newWidth: number,
    newHeight: number,
  ) => {
    // Convert angle from degrees to radians
    const centerPointX = originalX + originW / 2;
    const centerPointY = originalY + originH / 2;

    const newX = centerPointX - newWidth / 2;
    const newY = centerPointY - newHeight / 2;

    return { x: newX, y: newY };
  };

  const costOfPayment =
    find(currentLayout?.prices, (o) =>
      isEqualVal(o?.numberOfPicture, quantitySheet),
    )?.price ||
    currentLayout?.prices?.[0]?.price ||
    0;

  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  const handleNext = () => {
    openConfirmModal({
      content: `${t('common:printImageConfirm')}`,
      PrevButtonProps: { children: t('common:cancel') },
      NextButtonProps: { children: t('common:continue') },
      duration: 30000,
      isUseTimer: true,
      isTimerInBtnNext: true,
      onOk: () => {
        closeConfirmModal?.();
        handleProcessPrintImage();
      },
      onTimeUp: () => {
        closeConfirmModal();
        handleProcessPrintImage();
      },
      onClose: () => {
        resetTime();
        closeConfirmModal();
      },
    });
  };

  const handleProcessPrintImage = async () => {
    resetTime();
    audio?.playContinueClick?.();
    const isHasVideo =
      !shootingModeId || isEqualVal(shootingModeId, SHOOTING_TYPES.COUNTDOWN);
    const layoutAmount =
      +(currentLayout as LayoutPhotoModel)?.prices?.[0]?.price || 0;
    const listStickerFormData = map(
      filter(listSelectedSticker, (i) => i.width > 0 && i.height > 0),
      (item: {
        stickerId: any;
        rotate: any;
        width: any;
        height: any;
        translate: any[];
      }) => {
        const newSize = calculateSizeAfterRotation(
          item.width,
          item.height,
          item.rotate,
        );
        const newCoordinates = calculateCoordinatesAfterRotation(
          item.translate[0],
          item.translate[1],
          item.width,
          item.height,
          newSize.width,
          newSize.height,
        );
        return {
          stickerId: item.stickerId,
          rotate: Math.round(item.rotate),
          width: Math.round(
            (newSize.width / (photoContainer?.clientWidth || 0)) *
              (currentLayout?.width || 0),
          ),
          height: Math.round(
            (newSize.height / (photoContainer?.clientHeight || 0)) *
              (currentLayout?.height || 0),
          ),
          axisX: Math.round(
            (Math.round(newCoordinates.x) /
              (photoContainer?.clientWidth || 0)) *
              (currentLayout?.width || 0),
          ),
          axisY: Math.round(
            (Math.round(newCoordinates.y) /
              (photoContainer?.clientHeight || 0)) *
              (currentLayout?.height || 0),
          ),
        };
      },
    );
    dispatch(setSelfeAppStateAction({ appLoading: true }));

    // payload cho api xử lý ảnh video
    const processFilePayload = {
      frameId,
      layoutId,
      themeId,
      themeDetailId,
      transactionId,
      filterId,
      listImages: filter(pickedPhotos, (i) => i !== null).map((item) => ({
        fileName: item?.fileName || '',
        rotate: item?.rotate || 0,
        flip: item?.flip === -1 ? 0 : null,
        isDigitalBackground: !!backgroundId,
        digitalBackgroundId: backgroundId,
      })),
      isFile: true,
      isVideo: isHasVideo,
      voucherCode: appliedVoucherCode,
      purchaseDuration: diffFromDate(
        LastStartTimePurchase,
        LastStartTimeTakePhoto,
      ),
      captureDuration: diffFromDate(
        LastStartTimeTakePhoto,
        LastStartTimeEditPhoto,
      ),
      editDuration: diffFromDate(LastStartTimeEditPhoto, new Date()),
      captureMode: shootingModeId,
      printNumber: quantitySheet,
      layoutAmount,
      printAmount: costOfPayment - layoutAmount,
      discount: appliedVoucherValue,
      deposit: depositAmount - appliedVoucherValue,
      listSticker: listStickerFormData,
      isDigitalBackground: !!backgroundId,
      digitalBackgroundId: backgroundId,
    };
    // process ảnh
    await processPhotoTaken(processFilePayload);
    await delay(
      () => processVideoTaken(processFilePayload),
      TIME_DELAY_PROCESS_VIDEO,
    );

    delay(() => {
      navigate(getNextPath() || '');
      dispatch(setSelfeAppStateAction({ appLoading: false }));
    }, TIME_TO_DELAY_REDIRECT_PRINT);
  };

  const routes = useMemo(
    () =>
      [
        {
          path: SelfeRouteConst.THEME,
          element: <SelectTheme />,
          title: t(`${I18nNamespace.COMMON}:selectTheme`),
        },
        {
          path: SelfeRouteConst.STICKER,
          element: <SelectSticker />,
          title: t(`${I18nNamespace.COMMON}:selectSticker`),
        },
        {
          path: SelfeRouteConst.ADD_FILTER,
          element: <AddFilter />,
          title: t(`${I18nNamespace.COMMON}:editColor`),
        },
        {
          path: SelfeRouteConst.BACKGROUND,
          element: <SelectBackground />,
          title: t(`${I18nNamespace.COMMON}:background`),
        },
      ].filter((i) => {
        if (!isTurnOnSticker || frameId === FRAME_TO_PAYMENT_DIRECTLY) {
          return i.path !== SelfeRouteConst.STICKER;
        }
        if (!isTurnOnBackground) {
          return i.path !== SelfeRouteConst.BACKGROUND;
        }
        return i;
      }),

    [t, isTurnOnSticker, isTurnOnBackground, frameId],
  );

  const renderEditPhotoActions = () => (
    <PageActions
      className={cx({ 'edit-photo__page-actions--vertical': isVerticalScreen })}
      NextButtonProps={{ children: t('page:print') }}
      handleBack={handleBack}
      handleContinue={handleNext}
    />
  );

  return (
    <>
      <div className="edit-photo">
        <TimerText second={second} />
        {isVerticalScreen && renderEditPhotoActions()}
        <div className="page-title-margin edit-photo__title">
          {map(routes, (route) => {
            const isPageActive = isMatchPath(route?.path);

            return (
              <button type="button" onClick={() => navigate(route?.path)}>
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.H1}
                  data-text={route?.title}
                  className={`${
                    isPageActive ? 'page-title' : 'page-title-inactive'
                  }`}
                >
                  {route?.title}
                </Typography>
              </button>
            );
          })}
        </div>
        <CustomRoutes routes={routes} outerRoute={SelfeRouteConst.EDIT_PHOTO} />
      </div>
      {!isVerticalScreen && renderEditPhotoActions()}
      {isVerticalScreen && <TitleAndLogo hideTitle />}
    </>
  );
};
export default EditPhoto;

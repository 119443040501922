import { sortAscListOfNum } from 'helpers/array.helper';
import { isEqualVal } from 'helpers/string.helper';
import { filter, map, uniqBy } from 'lodash';
import { FramePhotoModel } from 'models/photo/frame.model';
import { LayoutPhotoModel } from 'models/photo/layout.model';

const getListQuantityOfPerSheet = (
  list: Array<LayoutPhotoModel> | undefined,
  frameId: FramePhotoModel['id'],
) =>
  sortAscListOfNum(
    map(
      uniqBy(
        filter(list, (o) => isEqualVal(o?.frameId, frameId)),
        'numberOfPicture',
      ),
      'numberOfPicture',
    ),
  );

const filterListLayoutByQuantity = (
  list: Array<LayoutPhotoModel> | undefined,
  frameId: FramePhotoModel['id'],
  numberOfPhoto: number,
): Array<LayoutPhotoModel> =>
  filter(
    list,
    (o) =>
      isEqualVal(o?.frameId, frameId) && o.numberOfPicture === numberOfPhoto,
  );

export { getListQuantityOfPerSheet, filterListLayoutByQuantity };

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TrackingStateModel } from 'models/photo/tracking.model';

const initialState: TrackingStateModel = {
  LastStartTimePurchase: new Date(),
  LastStartTimeTakePhoto: new Date(),
  LastStartTimeEditPhoto: new Date(),
};

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setTrackingStateAction: (
      state,
      action: PayloadAction<Partial<TrackingStateModel>>,
    ) => ({ ...state, ...action?.payload }),
    resetTrackingStateAction: () => ({ ...initialState }),
  },
});

export const { setTrackingStateAction, resetTrackingStateAction } =
  trackingSlice.actions;

export default trackingSlice.reducer;

export const BE_DEFINES = {
  HUB_TYPE: {
    BILL_ACCEPTOR: 1,
    REMOTE: 0,
  },
  ROBOT_ARM_BOOTH: 2,
  OVERHEAD_BOOTH: 3,
  SCREEN_TYPE: { HORIZONTAL: 1, VERTICAL: 2 },
  ARM_STATE: {
    MOTION_IN_IDLE: 'MOTION_IN_IDLE',
    MOTION_IN_PROGRESS: 'MOTION_IN_PROGRESS',
  },
};

import { useMemo } from 'react';
import { find } from 'lodash';
import { isEqualVal } from 'helpers/string.helper';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useAppSelector } from 'store/store-hooks';
import { useModal } from 'hooks/useModal';
import { useSoundContext } from 'context/SoundContext';
import { useTranslation } from 'hooks/useTranslation';
import { I18nNamespace } from 'constants/i18n.const';
import moment from 'moment';
import { diffFromDate } from 'helpers/time.helper';
import { useNavigate } from 'react-router-dom';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useFirstPushTransactionApiActionMutation } from 'store/api/transaction.slice.api';
import { useCreateMomoTransactionApiActionMutation } from 'store/api/payment.slice-api';

export function useCheckPayment() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { currentLayout } = useBoothAppContext();
  const { audio } = useSoundContext();
  const { openConfirmModal, closeConfirmModal } = useModal();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const [firstPushTransactionApiAction] =
    useFirstPushTransactionApiActionMutation();
  const [createMomoTransactionApiAction] =
    useCreateMomoTransactionApiActionMutation();

  const {
    quantitySheet,
    transactionId,
    depositAmount,
    appliedVoucherCode,
    appliedVoucherValue,
    momoTransactionExpireDate,
  } = useAppSelector((state) => state.selfeApp);

  const costOfPayment = useMemo(
    () =>
      find(currentLayout?.prices, (o) =>
        isEqualVal(o?.numberOfPicture, quantitySheet),
      )?.price ||
      currentLayout?.prices?.[0]?.price ||
      0,
    [currentLayout?.prices, quantitySheet],
  );

  const handleFirstSaveTransaction = () => {
    // Lưu transaction
    firstPushTransactionApiAction({
      id: transactionId,
      voucherCode: appliedVoucherCode,
      deposit: depositAmount - appliedVoucherValue,
    });
  };

  const handleCreateMomoQRCode = async () => {
    // create transaction
    await createMomoTransactionApiAction({
      OrderInfo: T(`${I18nNamespace.COMMON}:funTransaction`),
      OrderId: `${transactionId}_${moment().unix()}`,
      Amount: costOfPayment - depositAmount,
    });
  };

  // User click thanh toán bằng QRCode
  const handleCreateQRPayment = async () => {
    audio?.playContinueClick?.();
    if (depositAmount >= costOfPayment) {
      handleFirstSaveTransaction();
      navigate(getNextPath(getNextPath()) || '');
      return;
    }
    if (!diffFromDate(moment().toDate(), momoTransactionExpireDate)) {
      // nếu link qrcode momo hết hạn hoặc momoTransactionExpireDate = null
      // tạo link thanh toán MoMo mới
      await handleCreateMomoQRCode();
    }
    navigate(getNextPath() || '');
  };

  // User click khi đủ tiền
  const handleBeforeNextWarningAboutMoney = () => {
    if (depositAmount < costOfPayment) return;
    if (depositAmount > costOfPayment) {
      audio?.playRedundantDeposit?.();
      openConfirmModal({
        content: `${T('page:changeMoneyWarning')}`,
        onOk: () => {
          audio?.playContinueClick?.();
          handleFirstSaveTransaction();
          closeConfirmModal?.();
          navigate(getNextPath() || '');
        },
      });
    } else {
      audio?.playContinueClick?.();
      handleFirstSaveTransaction();
      navigate(getNextPath() || '');
    }
  };

  return {
    costOfPayment,
    isEnoughDeposit: depositAmount >= costOfPayment,
    handleBeforeNextWarningAboutMoney,
    handleCreateQRPayment,
    handleCreateMomoQRCode,
  };
}

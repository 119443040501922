/* eslint-disable no-param-reassign */
import { createFrameDashedBorder, hexToRgba } from 'helpers/color.helper';
import { resourceUrl } from 'helpers/url.helper';
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAppSelector } from 'store/store-hooks';

export const Context = React.createContext<ThemeContextReturnTypes>({});

interface ThemeContextProps {}
type ThemeContextReturnTypes = {};

export const ThemeContext: FC<PropsWithChildren<ThemeContextProps>> = ({
  children,
}) => {
  const { boothInfo } = useAppSelector((state) => state.booth);

  const handleAppendStyleToHead = (style: string) => {
    const styleElement = document.createElement('style');

    styleElement.innerHTML = style;

    document.head.appendChild(styleElement);
  };

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (boothInfo?.LogoImageUrl) {
      document.documentElement.style.setProperty(
        '--sync-primary-color',
        boothInfo?.PrimaryColor,
      );
      document.documentElement.style.setProperty(
        '--sync-secondary-color',
        boothInfo?.SecondaryColor,
      );
      document.documentElement.style.setProperty(
        '--sync-text-color',
        boothInfo?.TextColor,
      );
      document.documentElement.style.setProperty(
        '--sync-button-text-color',
        boothInfo?.ButtonTextColor,
      );
      document.documentElement.style.setProperty(
        '--sync-button-border-color',
        boothInfo?.ButtonBorderColor,
      );
      document.documentElement.style.setProperty(
        '--sync-input-background-color',
        boothInfo?.InputBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--sync-title-color',
        boothInfo?.TitleColor,
      );
      document.documentElement.style.setProperty(
        '--sync-border-title-1-color',
        boothInfo?.BorderTitleColor1,
      );
      document.documentElement.style.setProperty(
        '--sync-border-title-2-color',
        boothInfo?.BorderTitleColor2,
      );
      // Set background
      document.documentElement.style.setProperty(
        '--background-image-url',
        `url(${resourceUrl(boothInfo?.BackgroundImageUrl)})`,
      );
      // Set dashed border
      document.documentElement.style.setProperty(
        '--sync-live-view-dashed-border',
        `url("${createFrameDashedBorder(boothInfo?.TextColor)}")`,
      );
      document.documentElement.style.setProperty(
        '--sync-left-frame-dashed-border',
        `url("${createFrameDashedBorder(boothInfo?.PrimaryColor)}")`,
      );
      document.documentElement.style.setProperty(
        '--sync-right-frame-dashed-border',
        `url("${createFrameDashedBorder(boothInfo?.SecondaryColor)}")`,
      );
      document.documentElement.style.setProperty(
        '--sync-left-frame-background-color',
        hexToRgba(boothInfo?.PrimaryColor, 0.1),
      );
      document.documentElement.style.setProperty(
        '--sync-right-frame-background-color',
        hexToRgba(boothInfo?.SecondaryColor, 0.1),
      );
      handleAppendStyleToHead(boothInfo?.CustomCss);
    }
  }, [boothInfo]);

  return (
    <Context.Provider value={useMemo(() => ({}), [])}>
      {children}
    </Context.Provider>
  );
};

export const useThemeContext = () => useContext(Context);

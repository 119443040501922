import React, {
  FC,
  HtmlHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import cx from 'classnames';
import './photo-sheet.css';
import { LayoutPhotoModel } from 'models/photo/layout.model';
import { filter, map, round } from 'lodash';
import { resourceUrl } from 'helpers/url.helper';
import { ELEMENT_IDS } from 'constants/dom-element.const';
import PhotoFilter from './PhotoFilter';
import { OFFSET_PER } from './photo-sheet-utils';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import Sticker from '../sticker/Sticker';
import { setSelfeAppStateAction } from '../../store/features/app/selfeAppSlice';
import { useBoothAppContext } from '../../context/BoothAppContext';

export type PhotoSheetProps = HtmlHTMLAttributes<HTMLDivElement> & {
  isSelectSticker?: boolean;
  data?: UndefinedAll<LayoutPhotoModel>;
  filterValues?: number[];
  imageClick?: (imgIndex: number) => void;
  showPhotoIndex?: boolean;
  QRCodeProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  ImageSheetProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  PictureProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  forwardedRef?: React.MutableRefObject<HTMLInputElement | null>;
  setListSelectSticker?: (frameId: string) => void;
  handleSetTargetFrames?: () => void;
};

function getPercentSize(s1: number, s2: number, offset: number = 0): string {
  return `${round(!s2 ? 0 : (s1 / s2) * 100, 2) + offset}%`;
}

const PhotoSheet: FC<PhotoSheetProps> = ({
  data,
  filterValues,
  imageClick,
  showPhotoIndex = false,
  QRCodeProps,
  ImageSheetProps,
  PictureProps,
  setListSelectSticker,
  isSelectSticker = false,
  handleSetTargetFrames,
  ...rest
}) => {
  const refTarget = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { listBackgroundPhotos } = useBoothAppContext();
  const { backgroundId } = useAppSelector((state) => state.selfeApp);

  const backgroundURL = useMemo(
    () =>
      filter(listBackgroundPhotos?.data, (i) => i.id === backgroundId)[0]
        ?.imageUrl,
    [backgroundId, listBackgroundPhotos?.data],
  );

  useEffect(() => {
    dispatch(setSelfeAppStateAction({ photoContainer: refTarget?.current }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      {...rest}
      ref={refTarget}
      id={ELEMENT_IDS.PHOTO_SHEET}
      className={cx('pb-photo-sheet', rest?.className)}
    >
      <>
        {map(
          data?.pictures,
          (item, index) =>
            !!item?.image && (
              <PhotoFilter
                id={`filter-${index}`}
                imageAlt="pic-of-sheet"
                imageUrl={item?.image}
                onClick={() => imageClick?.(index)}
                filterValues={filterValues}
                showPhotoIndex={showPhotoIndex}
                photoIndex={index + 1}
                {...PictureProps}
                style={{
                  ...PictureProps?.style,
                  left: getPercentSize(item?.x, data?.width || 0, -OFFSET_PER),
                  top: getPercentSize(item?.y, data?.height || 0, -OFFSET_PER),
                  width: getPercentSize(
                    item?.width,
                    data?.width || 0,
                    2 * OFFSET_PER,
                  ),
                  height: getPercentSize(
                    item?.height,
                    data?.height || 0,
                    2 * OFFSET_PER,
                  ),
                }}
                rotate={`${item?.rotate}deg`}
                flip={`${item?.flip ? item.flip : '1'}`}
                className={cx(
                  'pb-photo-sheet-picture-item',
                  PictureProps?.className,
                )}
                backgroundURL={backgroundURL}
              />
            ),
        )}
        <img
          alt="sheet"
          src={resourceUrl(data?.image)}
          {...ImageSheetProps}
          className={cx(
            'pb-photo-sheet-theme-image',
            ImageSheetProps?.className,
          )}
        />
        {data?.QRCode?.image && (
          <img
            alt="qr-code-of-sheet"
            src={data?.QRCode?.image}
            {...QRCodeProps}
            style={{
              ...QRCodeProps?.style,
              top: data?.QRCode?.y,
              left: data?.QRCode?.x,
            }}
            className={cx('pb-photo-sheet-qr-code', QRCodeProps?.className)}
          />
        )}
      </>
      {!showPhotoIndex && (
        <Sticker
          isSelectSticker={isSelectSticker}
          className="absolute top-0 left-0 w-full h-full"
        />
      )}
    </div>
  );
};

export default PhotoSheet;

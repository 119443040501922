import useOnlineState from 'beautiful-react-hooks/useOnlineState';
import React, { FC, PropsWithChildren, useContext } from 'react';

export const Context = React.createContext<AppContextReturnTypes>({
  isOnline: true,
});

interface AppContextProps {}
type AppContextReturnTypes = {
  isOnline: boolean;
};

export const AppContext: FC<PropsWithChildren<AppContextProps>> = ({
  children,
}) => {
  const isOnline = useOnlineState();

  return <Context.Provider value={{ isOnline }}>{children}</Context.Provider>;
};

export const useAppContext = () => useContext(Context);

import { FRAME_TIME_DURATION } from './photo.const';

export const INIT_POPUP_STATE = {
  open: false,
  content: '',
  hideClose: true,
  hidePrevNext: false,
  hidePrev: false,
  hideNext: false,

  isUseTimer: false,
  duration: FRAME_TIME_DURATION,
};

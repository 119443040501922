import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BE_DEFINES } from 'constants/backend-defines.const';
import { includesId } from 'helpers/array.helper';
import { isEqualVal } from 'helpers/string.helper';
import { BoothStateModel } from 'models/photo/booth.model';
import { getBoothApiAction } from 'store/api/booth.slice-api';

const initialState: BoothStateModel = {
  boothId: '',
  boothInfo: null,

  isNormal: true,
  isOverhead: false,
  isRobotArm: false,
  isVerticalScreen: false,
  isRotatablePhoto: true,
  isTurnOnSticker: false,
  isTurnOnBackground: false,
  isTurnOnPayment: true,

  loadingInList: false,
  loadingInitial: true,
};

export const boothSlice = createSlice({
  name: 'booth',
  initialState,
  reducers: {
    setBoothStateAction: (
      state,
      action: PayloadAction<Partial<BoothStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(getBoothApiAction.matchPending, (state) => ({
        ...state,
        loadingInList: false,
      }))
      .addMatcher(getBoothApiAction.matchRejected, (state) => ({
        ...state,
        ...initialState,
      }))
      .addMatcher(getBoothApiAction.matchFulfilled, (state, { payload }) => ({
        ...state,
        boothId: payload?.boothId || '',
        boothInfo: payload,
        loadingInList: false,
        isNormal: !includesId(
          [BE_DEFINES.OVERHEAD_BOOTH, BE_DEFINES.OVERHEAD_BOOTH],
          payload?.Type,
        ),
        isVerticalScreen: isEqualVal(
          payload?.ScreenType,
          BE_DEFINES.SCREEN_TYPE.VERTICAL,
        ),
        isRobotArm: isEqualVal(payload?.Type, BE_DEFINES.ROBOT_ARM_BOOTH),
        isOverhead: isEqualVal(payload?.Type, BE_DEFINES.OVERHEAD_BOOTH),

        isRotatablePhoto: true, // !!payload?.IsRotatePhoto,
        isTurnOnSticker: !!payload?.IsSticker,
        isTurnOnBackground: !!payload?.IsDigitalBackground,
        isTurnOnPayment: true, // !!payload?.IsPay,
      }));
  },
});

export const { setBoothStateAction } = boothSlice.actions;

export default boothSlice.reducer;

import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import qs from 'query-string';
import {
  ListFramePhotoRequestModel,
  ListFramePhotoResponseModel,
} from 'models/photo/frame.model';

export function listFramePhoto(payload: ListFramePhotoRequestModel) {
  return authorizedRequest.get<
    ListFramePhotoResponseModel,
    ListFramePhotoResponseModel
  >(`${PREFIX_API.API}/frame/getlist?${qs.stringify(payload)}`);
}

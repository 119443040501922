import { BE_DEFINES } from 'constants/backend-defines.const';
import { checkSimilarNumber } from 'helpers/math.helper';
import { isEqualVal } from 'helpers/string.helper';
import { CapturePositionModel } from 'models/photo/camera.model';
import {
  RobotArmPositionModel,
  RobotArmPositionWithCaptureNameModel,
} from 'models/photo/robot-arm.model';

function convertCapturePositionToArmPosition(
  capturePosition: CapturePositionModel,
): RobotArmPositionWithCaptureNameModel {
  return {
    capturePositionId: capturePosition?.id,
    capturePositionName: capturePosition?.name,
    point: {
      x: capturePosition?.positionX,
      y: capturePosition?.positionY,
      z: capturePosition?.positionZ,
    },
    orientation: {
      roll: capturePosition?.rotationRoll,
      pitch: capturePosition?.rotationPitch,
      yaw: capturePosition?.rotationYaw,
    },
  };
}

function checkSamePosition(
  position1: RobotArmPositionModel | null | undefined,
  position2: RobotArmPositionModel | null | undefined,
): boolean {
  if (!position1 || !position2) return false;

  if (
    !checkSimilarNumber(
      position1?.orientation?.pitch,
      position2?.orientation?.pitch,
    ) ||
    !checkSimilarNumber(
      position1?.orientation?.roll,
      position2?.orientation?.roll,
    ) ||
    !checkSimilarNumber(
      position1?.orientation?.yaw,
      position2?.orientation?.yaw,
    ) ||
    !checkSimilarNumber(position1?.point?.x, position2?.point?.x) ||
    !checkSimilarNumber(position1?.point?.y, position2?.point?.y) ||
    !checkSimilarNumber(position1?.point?.z, position2?.point?.z)
  ) {
    return false;
  }

  return true;
}

function checkIsIdleArm(status: string | null | undefined): boolean {
  return isEqualVal(status, BE_DEFINES.ARM_STATE.MOTION_IN_IDLE);
}

export {
  convertCapturePositionToArmPosition,
  checkSamePosition,
  checkIsIdleArm,
};

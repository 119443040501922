import moment from 'moment';
import { addZeroPrefix } from './math.helper';

/**
 * Trả ra thời gian tính toán giữa hai mốc
 *
 * @category all Date
 * @param any
 * @param any
 * @returns Returns diff time.
 * @example
 *
 */
function diffFromDate(
  dTime1: Date | null | undefined,
  dTime2: Date | null | undefined,
  isExact = false,
): number | null {
  if (dTime1 && dTime2) {
    const _diff = moment(dTime1).diff(moment(dTime2), 'seconds');
    return isExact ? _diff : Math.abs(_diff);
  }
  return isExact ? null : 0;
}

/**
 * get date with format 6 -> 00:06
 *
 * get date with format 61 -> 01:01
 *
 * get date with format 3601 -> 01:00:01
 */
function secondToTimeFormat(second: number | undefined = 0): string {
  // eslint-disable-next-line no-param-reassign
  if (!second) second = 0;
  const hourNum = Math.floor(second / 3600);
  const minuteNum = Math.floor((second - hourNum * 3600) / 60);
  const secondNum = second - hourNum * 3600 - minuteNum * 60;
  return `${hourNum ? `${addZeroPrefix(hourNum, true)}:` : ''}${addZeroPrefix(
    minuteNum,
    true,
  )}:${addZeroPrefix(secondNum, true)}`;
}

export { diffFromDate, secondToTimeFormat };

import { PREFIX_API } from 'constants/api.const';
import {
  PrintPhotoRequestModel,
  PrintPhotoResponseModel,
  UpdateCloudRequestModel,
  UpdateCloudResponseModel,
} from 'models/photo/print.model';
import { baseSliceApi } from './base.slice-api';

export const printApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    printPhotoApiAction: build.mutation<
      PrintPhotoResponseModel,
      PrintPhotoRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.API}/print/printimage`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'PrintPhoto', id: 'LIST' }],
      extraOptions: { maxRetries: 0 },
    }),
    updateDataApiAction: build.mutation<
      UpdateCloudResponseModel,
      UpdateCloudRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.API}/file/uploaddata`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  usePrintPhotoApiActionMutation,
  useUpdateDataApiActionMutation,
} = printApi;

export const {
  endpoints: { printPhotoApiAction, updateDataApiAction },
} = printApi;

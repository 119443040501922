/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { filter, includes, map, range, size, slice } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import useInterval from 'beautiful-react-hooks/useInterval';
import { useTranslation } from 'react-i18next';
import useConditionalTimeout from 'beautiful-react-hooks/useConditionalTimeout';
import cx from 'classnames';
import addImagePhoto from 'assets/images/add-image.png';
import { Icons } from 'assets/icon/Icons';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useModal } from 'hooks/useModal';
import { useTimer } from 'hooks/useTimer';
import {
  useGenerateVideoApiActionMutation,
  useRecordVideoApiActionMutation,
  useStartRecordVideoApiActionMutation,
  useTakePhotoApiActionMutation,
  useBackStopCameraLiveViewApiActionMutation,
} from 'store/api/camera.slice-api';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import {
  TIME_DEBOUNCE_TAKE_PHOTO,
  SECOND_PER_COUNTDOWN,
  SECOND_VAL,
  SHOOTING_TYPES,
  BUTTON_AUTO_CLICK_TIME_DURATION,
  TIME_SHOW_POPOVER_GUIDE_DURATION,
  KEYCODE_TO_TAKE_PHOTO,
  TIME_TO_CAMERA_FLASH,
  TIME_TO_SHOW_PHOTO,
  TIME_CONFIRM_BEFORE_NEXT,
  TIME_DEBOUNCE_UPDATE_ROBOT_ARM,
  CONTINUE_TO_START_TIME_DURATION,
} from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import { BROADCAST_EVENTS } from 'constants/dom-element.const';
import { isEqualVal } from 'helpers/string.helper';
import { sortAscListOfNum } from 'helpers/array.helper';
import { dv } from 'helpers/common.helper';
import { processedImageUrl, liveViewUrl } from 'helpers/url.helper';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import {
  useLazyGetRobotArmPositionApiActionQuery,
  useLazyGetRobotArmStatusApiActionQuery,
  useUpdateRobotArmPositionApiActionMutation,
} from 'store/api/robot-arm.slice-api';
import { emitSeqLog } from 'functions/seq-logging.func';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import Button from 'components/button/Button';
import Loader from 'components/loader/Loader';
import PopoverBox from 'components/popover/PopoverBox';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import replayIcon from 'assets/v2/images/replay.png';
import useBeautifulReactDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { RobotArmPositionWithCaptureNameModel } from 'models/photo/robot-arm.model';
import {
  checkIsIdleArm,
  checkSamePosition,
} from '../shooting-position/shooting-position-helper';
import './shooting.css';

function Shooting() {
  const { t } = useTranslation([
    I18nNamespace.COMMON,
    I18nNamespace.PAGE,
    I18nNamespace.WARNING,
  ]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openErrorModal, openConfirmModal, closeConfirmModal } = useModal();
  const { listPhotoTakens, listCameras, currentLayout } = useBoothAppContext();
  const { getNextPath, getPrevPath } = useNewPhotoLifeCycleStep();
  const {
    transactionId,
    shootingModeId,
    isRetakePhoto,
    isShootingOfRetakingPhoto,
    wasRetakePhoto,
    listTimesMappingCapturePosition,
  } = useAppSelector((state) => state.selfeApp);
  const { isRobotArm, isOverhead, isVerticalScreen } = useAppSelector(
    (s) => s.booth,
  );
  const [takePhotoAction] = useTakePhotoApiActionMutation();
  const [startRecordVideoAction] = useStartRecordVideoApiActionMutation();
  const [recordVideoAction] = useRecordVideoApiActionMutation();
  const [generateVideoAction] = useGenerateVideoApiActionMutation();
  const [updateRobotArmPositionApiAction] =
    useUpdateRobotArmPositionApiActionMutation();
  const [getRobotArmStatusApiAction] = useLazyGetRobotArmStatusApiActionQuery();
  const [getRobotArmPositionApiAction] =
    useLazyGetRobotArmPositionApiActionQuery();
  const { audio } = useSoundContext();
  const [backStopLiveViewAction] = useBackStopCameraLiveViewApiActionMutation();

  const firstStartRef = useRef<boolean>(false);
  const thumbImgRef = useRef<HTMLDivElement>(null);
  const btnSelectRetakeRef = useRef<HTMLButtonElement>(null);
  const [isShowThumbnailRect, setIsShowThumbnailGuideRect] = useState(false);
  const [isShowBtnRetakeRect, setIsShowBtnRetakeRect] = useState(false);

  const [countDownSec, setCountDownSec] = useState(SECOND_PER_COUNTDOWN);
  const [isStartCountDownShooting, setIsStartCountDownShooting] =
    useState(false);
  const [isFreezeShooting, setIsFreezeShooting] = useState(false);
  const [isFlashCamera, setIsFlashCamera] = useState(false);
  const [imageHash, setImageHash] = useState(Date.now());
  const [liveViewHash] = useState(Date.now());
  const [selectImageIndex, setSelectImageIndex] = useState(0);
  const [listIndexOfRetake, setListIndexOfRetake] = useState<number[]>([]);

  const isCountDownMode = useMemo(
    () =>
      !shootingModeId || isEqualVal(shootingModeId, SHOOTING_TYPES.COUNTDOWN),
    [shootingModeId],
  );

  const sizeOfNeedToTakePhoto = useMemo(
    () =>
      isRetakePhoto
        ? size(listPhotoTakens?.data) - size(listIndexOfRetake)
        : size(listPhotoTakens?.data),
    [isRetakePhoto, listPhotoTakens?.data, listIndexOfRetake],
  );

  const numberOfTakePicture = useMemo(
    () => dv(currentLayout?.numberOfTakePicture, 0),
    [currentLayout?.numberOfTakePicture],
  );

  // start button auto click
  const { second: btnStartSeconds } = useTimer({
    isEnabled: isCountDownMode && !!listCameras?.data?.[0]?.SessionOpen,
    totalMilliseconds: CONTINUE_TO_START_TIME_DURATION,
    timeUpFunc: () => handleStartPhoto(),
  });

  // continue button auto click
  const { second: btnContinueSeconds } = useTimer({
    isEnabled: !isShootingOfRetakingPhoto && isRetakePhoto && wasRetakePhoto,
    totalMilliseconds: BUTTON_AUTO_CLICK_TIME_DURATION,
    timeUpFunc: () => handleRedirectNext(),
  });

  const handleMoveRobotArm = useBeautifulReactDebouncedCallback(
    async (capturePositionIndex: number = 0) => {
      if (
        isRobotArm &&
        listTimesMappingCapturePosition?.[capturePositionIndex]
      ) {
        const statusRes = await getRobotArmStatusApiAction();
        const armPositionRes = await getRobotArmPositionApiAction();
        if (
          checkIsIdleArm(statusRes?.data?.state) &&
          !checkSamePosition(
            armPositionRes?.data,
            listTimesMappingCapturePosition?.[capturePositionIndex],
          )
        ) {
          await updateRobotArmPositionApiAction(
            listTimesMappingCapturePosition?.[
              capturePositionIndex
            ] as RobotArmPositionWithCaptureNameModel,
          );
        }
      }
    },
    [isRobotArm],
    TIME_DEBOUNCE_UPDATE_ROBOT_ARM,
    { trailing: false, leading: true },
  );

  const handleRedirectPrev = () => {
    backStopLiveViewAction();
    navigate(getPrevPath() || '');
  };

  const handleRedirectNext = (isShowConfirmBefore = false) => {
    emitSeqLog?.({ messageTemplate: `[Shooting] Shooting Finish!` });
    if (isShowConfirmBefore) {
      audio?.playRedundantDeposit?.();
      handleConfirmBeforeNext();
    } else {
      if (isCountDownMode) generateVideoAction();
      navigate(getNextPath() || '');
    }
  };

  const handleConfirmBeforeNext = () => {
    openConfirmModal({
      content: `${t('warning:cannotRetakeTitle')}`,
      PrevButtonProps: { children: t('common:no') },
      NextButtonProps: { children: t('common:yes') },
      duration: TIME_CONFIRM_BEFORE_NEXT,
      isUseTimer: true,
      onTimeUp: closeConfirmModal,
      onOk: () => {
        closeConfirmModal?.();
        handleRedirectNext();
      },
      onClose: closeConfirmModal,
    });
  };

  const handleStartRecordVideo = async () => {
    await startRecordVideoAction().then((res: any) => {
      if (res?.error) openErrorModal();
    });
  };

  // xử lý bắt đầu chụp ảnh count down
  const handleStartPhoto = () => {
    emitSeqLog?.({
      messageTemplate: `[Shooting] Start shooting with vid (handleStartPhoto)`,
    });
    if (!firstStartRef.current) {
      firstStartRef.current = true;
      handleStartRecordVideo();
      setIsStartCountDownShooting(true);
    }
  };

  const closeAllPopover = () => {
    setIsShowThumbnailGuideRect(false);
    setIsShowBtnRetakeRect(false);
  };

  // chọn ảnh sẽ chụp lại
  const handleSelectResultOfPhoto = (index: number) => () => {
    if (!isRetakePhoto || !isShootingOfRetakingPhoto) {
      setSelectImageIndex(index);
    }
  };

  // Click chọn ảnh để chụp lại
  const handleToggleRetakePhoto = () => {
    setListIndexOfRetake((l) =>
      sortAscListOfNum(
        includes(l, selectImageIndex)
          ? filter(l, (i) => !isEqualVal(selectImageIndex, i))
          : [...l, selectImageIndex],
      ),
    );
  };

  // Confirm chụp lại ảnh
  const handleContinueOrConfirmReTakePhoto = () => {
    if (wasRetakePhoto || !size(listIndexOfRetake)) {
      handleRedirectNext(!wasRetakePhoto);
    } else {
      audio?.playRetakePhoto?.();
      closeAllPopover();
      openConfirmModal({
        content: `${t('page:retakeAPhotoConfirm')}`,
        onOk: async () => {
          closeConfirmModal?.();
          await handleMoveRobotArm(listIndexOfRetake?.[0]);
          dispatch(setSelfeAppStateAction({ isShootingOfRetakingPhoto: true }));
          if (isCountDownMode) {
            setCountDownSec(SECOND_PER_COUNTDOWN);
            handleStartRecordVideo();
          }
        },
      });
    }
  };

  // Sau khi goi api chụp ảnh
  const handleAfterTakeAPhoto = useCallback(() => {
    if (isRetakePhoto) setListIndexOfRetake((l) => slice(l, 1));
    setTimeout(() => setImageHash(Date.now()), TIME_TO_CAMERA_FLASH);
  }, [isRetakePhoto]);

  // Chụp bằng remote
  const handleTakePhoto = useDebouncedCallback(
    useCallback(async () => {
      if (isRetakePhoto && !isShootingOfRetakingPhoto) return;
      if (!transactionId || isCountDownMode || isFreezeShooting) return;
      setIsFreezeShooting(true);
      setIsFlashCamera(true);
      audio?.playCameraShutter();
      await takePhotoAction({
        transactionid: transactionId,
        filename: isRetakePhoto
          ? listPhotoTakens?.data?.[listIndexOfRetake?.[0]] ?? ''
          : '',
      });
      await handleMoveRobotArm(
        (isRetakePhoto ? listIndexOfRetake?.[0] : sizeOfNeedToTakePhoto) + 1,
      );
      handleAfterTakeAPhoto();
      setIsFreezeShooting(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      handleAfterTakeAPhoto,
      isCountDownMode,
      isFreezeShooting,
      isRetakePhoto,
      isShootingOfRetakingPhoto,
      listIndexOfRetake,
      listPhotoTakens?.data,
      takePhotoAction,
      transactionId,
    ]),
    TIME_DEBOUNCE_TAKE_PHOTO,
    { trailing: false, leading: true },
  );

  // Chụp tự động
  const [, clearInterval] = useInterval(async () => {
    if (isRetakePhoto && !isShootingOfRetakingPhoto) return;
    if (!isStartCountDownShooting || !isCountDownMode || isFreezeShooting)
      return;
    if (sizeOfNeedToTakePhoto < numberOfTakePicture) {
      setCountDownSec((sec) => (sec - 1) % 10);
      countDownSec > 0 && audio?.playSecondTick();
    }
    if (transactionId && countDownSec === 0) {
      setIsFreezeShooting(true);
      setIsFlashCamera(true);
      audio?.playCameraShutter();
      const res: any = await recordVideoAction({
        transactionid: transactionId,
        filename: isRetakePhoto
          ? listPhotoTakens?.data?.[listIndexOfRetake?.[0]] ?? ''
          : '',
      });
      if (sizeOfNeedToTakePhoto + 1 < numberOfTakePicture || !!res?.error) {
        await handleMoveRobotArm(
          (isRetakePhoto ? listIndexOfRetake?.[0] : sizeOfNeedToTakePhoto) + 1,
        );
        await handleStartRecordVideo();
        setCountDownSec(SECOND_PER_COUNTDOWN);
      }
      handleAfterTakeAPhoto();
      setIsFreezeShooting(false);
    }
  }, SECOND_VAL);

  // Close popover guide
  useConditionalTimeout(
    () => closeAllPopover(),
    TIME_SHOW_POPOVER_GUIDE_DURATION,
    isShowBtnRetakeRect && isShowThumbnailRect,
  );

  // Show kết quả chụp ảnh
  useConditionalTimeout(
    () => {
      dispatch(setSelfeAppStateAction({ isRetakePhoto: true }));
      setIsShowThumbnailGuideRect(true);
      setIsShowBtnRetakeRect(true);
    },
    TIME_TO_SHOW_PHOTO,
    !isRetakePhoto && sizeOfNeedToTakePhoto >= numberOfTakePicture,
  );

  // Show kết quả chụp ảnh
  useConditionalTimeout(
    () => {
      clearInterval();
      dispatch(
        setSelfeAppStateAction({
          wasRetakePhoto: true,
          isShootingOfRetakingPhoto: false,
        }),
      );
    },
    TIME_TO_SHOW_PHOTO,
    isRetakePhoto &&
      isShootingOfRetakingPhoto &&
      sizeOfNeedToTakePhoto >= numberOfTakePicture,
  );

  // unfreeze sau số giây chụp ảnh
  useEffect(() => {
    if (isFlashCamera) {
      setTimeout(() => {
        setIsFlashCamera(false);
      }, TIME_TO_CAMERA_FLASH);
    }
  }, [isFlashCamera]);

  // Realtime remote to take a picture
  useEffect(() => {
    if (!isCountDownMode) {
      document.addEventListener(BROADCAST_EVENTS.TAKE_PHOTO, handleTakePhoto);
      return () => {
        document.removeEventListener(
          BROADCAST_EVENTS.TAKE_PHOTO,
          handleTakePhoto,
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountDownMode]);

  // Realtime remote to take a picture by logitech present
  useEffect(() => {
    if (!isCountDownMode) {
      const listener = (e: KeyboardEvent) => {
        if (includes(KEYCODE_TO_TAKE_PHOTO, e.keyCode)) {
          handleTakePhoto();
        }
      };
      document.addEventListener('keyup', listener);
      return () => {
        document.removeEventListener('keyup', listener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountDownMode]);

  useEffect(() => {
    handleMoveRobotArm(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTakePhotoThumbnail = () => (
    <div ref={thumbImgRef} className="flex recently-photo">
      {map(range(numberOfTakePicture), (_num, ind) => {
        const takenImg = listPhotoTakens?.data?.[ind];
        const isThisPicRetakePhoto =
          !!takenImg && isRetakePhoto && includes(listIndexOfRetake, ind);
        const assetUrl = takenImg
          ? processedImageUrl(transactionId, takenImg, imageHash)
          : addImagePhoto;
        const isShootingPhoto =
          isThisPicRetakePhoto &&
          isShootingOfRetakingPhoto &&
          isEqualVal(listIndexOfRetake?.[0], ind);

        return (
          <button
            key={_num}
            type="button"
            className={cx('flex items-center justify-center photo-slot', {
              'not-has-photo': !takenImg,
              'has-photo': !!takenImg,
              'retaking-photo-thumb': isShootingPhoto,
              'selected-photo':
                isRetakePhoto &&
                !isShootingOfRetakingPhoto &&
                isEqualVal(selectImageIndex, ind),
            })}
            onClick={handleSelectResultOfPhoto(ind)}
          >
            <img src={assetUrl} alt="thumb" />
            {isThisPicRetakePhoto && (
              <div
                className={cx('photo-slot-retake-layer', {
                  'blink-opacity': isShootingPhoto,
                })}
              >
                <Icons.Round />
              </div>
            )}
          </button>
        );
      })}
      <div className="grow flex items-center justify-center count-recently-photo">
        <Typography
          variant={TYPOGRAPHY_VARIANTS.SMALL}
          className="text-center font-semibold"
          color={TypographyColor.SECONDARY}
        >
          {sizeOfNeedToTakePhoto}/{numberOfTakePicture}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={cx('flex flex-col items-center shooting-page', {
          'shooting-page-overhead': isOverhead,
        })}
      >
        <div className="action-wrapper">
          {(() => {
            switch (true) {
              case !isShootingOfRetakingPhoto && isRetakePhoto:
                return (
                  <>
                    <Button onClick={handleContinueOrConfirmReTakePhoto}>
                      {t('common:continue')}{' '}
                      {wasRetakePhoto && `(${btnContinueSeconds})`}
                    </Button>
                  </>
                );
              case isCountDownMode && !isStartCountDownShooting:
                return (
                  <>
                    <Button color="default" onClick={handleRedirectPrev}>
                      {t('common:back')}
                    </Button>
                    <Button
                      onClick={handleStartPhoto}
                      disabled={!listCameras?.data?.[0]?.SessionOpen}
                    >
                      {t('common:start')} ({btnStartSeconds})
                    </Button>
                  </>
                );
              case isCountDownMode:
                return (
                  <Button
                    disabled={isFreezeShooting}
                    loading={isFreezeShooting}
                  >
                    {Math.max(countDownSec, 0)}
                  </Button>
                );
              default:
                return (
                  <>
                    {!size(listPhotoTakens?.data) && (
                      <Button color="default" onClick={handleRedirectPrev}>
                        {t('common:back')}
                      </Button>
                    )}
                    <Button
                      onClick={handleTakePhoto}
                      disabled={
                        isFreezeShooting || !listCameras?.data?.[0]?.SessionOpen
                      }
                      loading={isFreezeShooting}
                    >
                      {t('page:clickToTakePhoto')}
                    </Button>
                  </>
                );
            }
          })()}
        </div>
        {isVerticalScreen && (
          <div className="recently-photo-wrapper">
            {renderTakePhotoThumbnail()}
          </div>
        )}
        <div
          className={cx('flex items-center justify-center webcam-frame', {
            'webcam-frame-3per2':
              isEqualVal(currentLayout?.ratioX, 3) &&
              isEqualVal(currentLayout?.ratioY, 2),
            'webcam-frame-5per4':
              isEqualVal(currentLayout?.ratioX, 5) &&
              isEqualVal(currentLayout?.ratioY, 4),
            'webcam-frame-4per3':
              isEqualVal(currentLayout?.ratioX, 4) &&
              isEqualVal(currentLayout?.ratioY, 3),
          })}
        >
          <div
            className="dashed-border-select-frame webcam-frame-wrapper"
            style={{
              aspectRatio: `${currentLayout?.ratioX || 1} / ${
                currentLayout?.ratioY || 1
              }`,
            }}
          >
            {isFlashCamera && (
              <Loader className="freeze-frame" spin={<></>} loading />
            )}
            <img
              src={liveViewUrl(currentLayout?.ratio, liveViewHash)}
              alt="camera"
            />
            {isRetakePhoto && !isShootingOfRetakingPhoto && (
              <>
                {listPhotoTakens?.data?.[selectImageIndex] && (
                  <img
                    className="retake-photo-img-result"
                    src={processedImageUrl(
                      transactionId,
                      listPhotoTakens?.data?.[selectImageIndex],
                      imageHash,
                    )}
                    alt="result img"
                  />
                )}
                <button
                  ref={btnSelectRetakeRef}
                  type="button"
                  className={cx('btn-retake-a-photo', {
                    invisible: wasRetakePhoto,
                  })}
                  onClick={handleToggleRetakePhoto}
                  disabled={wasRetakePhoto}
                >
                  <img src={replayIcon} alt="replay icon" />
                  <Typography
                    variant={TYPOGRAPHY_VARIANTS.SMALL}
                    className="text-retake-a-photo"
                  >
                    {includes(listIndexOfRetake, selectImageIndex)
                      ? t('page:noRetakeAPhoto')
                      : t('page:retakeAPhoto')}
                  </Typography>
                </button>
              </>
            )}
          </div>
        </div>
        {!isVerticalScreen && (
          <div className="recently-photo-wrapper">
            {renderTakePhotoThumbnail()}
          </div>
        )}
      </div>
      <PopoverBox
        anchorDimension={thumbImgRef?.current?.getBoundingClientRect?.()}
        popoverDir={isVerticalScreen ? 'bottom' : 'left'}
        content={`${t('page:clickWatchImageGuideInfo')}`}
        open={isShowThumbnailRect}
      />
      <PopoverBox
        anchorDimension={btnSelectRetakeRef?.current?.getBoundingClientRect?.()}
        popoverDir={
          isVerticalScreen ? 'bottom' : isOverhead ? 'left' : 'bottom'
        }
        content={`${t('page:selectRetakeGuideInfo')}`}
        open={isShowBtnRetakeRect}
      />
      {isVerticalScreen && (
        <TitleAndLogo className="shooting-mode__title" hideTitle />
      )}
    </>
  );
}

export default Shooting;

import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CONFIG from 'config';
import { SocketEventDataModel } from 'models/photo/socket.model';
import { BE_DEFINES } from 'constants/backend-defines.const';
import { isEqualVal } from 'helpers/string.helper';
import { BROADCAST_EVENTS } from 'constants/dom-element.const';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import {
  TIME_DEBOUNCE_TAKE_PHOTO,
  PAYMENT_DEPOSIT_DEBOUNCE_TIME,
  TIME_BANKING_DEBOUNCE_TIME,
} from 'constants/photo.const';
import { emitSeqLog } from 'functions/seq-logging.func';

export const Context = React.createContext<SignalRContextReturnTypes>({
  socketProxy: null,
});

interface SignalRContextProps {}
type SignalRContextReturnTypes = {
  socketProxy: any;
};

export const SignalRContext: FC<PropsWithChildren<SignalRContextProps>> = ({
  children,
}) => {
  const [connection, setConnection] = useState<any>(null);
  const [socketProxy, setSocketProxy] = useState<any>(null);

  const handleDispatchDeposit = useDebouncedCallback(
    (data: SocketEventDataModel) => {
      const billAcceptorEvent = new CustomEvent(
        BROADCAST_EVENTS.BILL_ACCEPTOR,
        { detail: data },
      );
      document.dispatchEvent(billAcceptorEvent);
      emitSeqLog?.({
        messageTemplate: `[Socket] Event Deposit!`,
        properties: { data },
      });
    },
    [],
    PAYMENT_DEPOSIT_DEBOUNCE_TIME,
    { trailing: false, leading: true },
  );

  const handleDispatchTakePhoto = useDebouncedCallback(
    () => {
      const takePhotoEvent = new CustomEvent(BROADCAST_EVENTS.TAKE_PHOTO);
      document.dispatchEvent(takePhotoEvent);
      emitSeqLog?.({ messageTemplate: `[Socket] Event Take Photo!` });
    },
    [],
    TIME_DEBOUNCE_TAKE_PHOTO,
    { trailing: false, leading: true },
  );

  const handleDispatchQRBanking = useDebouncedCallback(
    (data: SocketEventDataModel) => {
      const qrBankingEvent = new CustomEvent(BROADCAST_EVENTS.QR_BANKING, {
        detail: data,
      });
      document.dispatchEvent(qrBankingEvent);
      emitSeqLog?.({
        messageTemplate: `[Socket] Event Banking!`,
        properties: { data },
      });
    },
    [],
    TIME_BANKING_DEBOUNCE_TIME,
    { trailing: false, leading: true },
  );

  // Event from machine
  const startWebSocket = async () => {
    try {
      const con = (window as any).$.hubConnection(CONFIG.REACT_APP_WS_URL);
      const proxy = con.createHubProxy('FSHubs');
      proxy.on('broadcastMessage', (data: SocketEventDataModel) => {
        if (isEqualVal(data?.typeHub, BE_DEFINES.HUB_TYPE.BILL_ACCEPTOR)) {
          handleDispatchDeposit(data);
        } else if (isEqualVal(data?.typeHub, BE_DEFINES.HUB_TYPE.REMOTE)) {
          handleDispatchTakePhoto();
        }
        if (data?.orderId) {
          handleDispatchQRBanking(data);
        }
      });

      await con.start();
      setConnection(con);
      setSocketProxy(proxy);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const closeConnection = useCallback(async () => {
    try {
      connection && (await connection.stop());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [connection]);

  useEffect(() => {
    !connection && startWebSocket();

    return () => {
      closeConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeConnection, connection]);

  return (
    <Context.Provider
      value={useMemo(
        () => ({ closeConnection, connection, socketProxy }),
        [closeConnection, connection, socketProxy],
      )}
    >
      {children}
    </Context.Provider>
  );
};

export const useSignalRContext = () => useContext(Context);

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store';
import { LoggingContext } from 'context/LoggingContext';
import { AppContext } from 'context/AppContext';
import { SoundContext } from 'context/SoundContext';
import { SignalRContext } from 'context/SignalRContext';
// import { PrintingPhotoRouteConst } from 'constants/route.const';
import SelfeLayout from 'containers/selfe-layout/SelfeLayout';
import { ThemeContext } from 'context/ThemeContext';
// import PrintingPhotoLayout from 'containers/printing-photo-layout/PrintingPhotoLayout';

const router = createBrowserRouter([
  // {
  //   path: `${PrintingPhotoRouteConst.HOME}/*`,
  //   element: <PrintingPhotoLayout />,
  // },
  {
    path: '*',
    element: <SelfeLayout />,
  },
]);

function App() {
  return (
    <ReduxProvider store={store}>
      <LoggingContext>
        <SoundContext>
          <AppContext>
            <ThemeContext>
              <SignalRContext>
                <RouterProvider router={router} />
              </SignalRContext>
            </ThemeContext>
          </AppContext>
        </SoundContext>
      </LoggingContext>
    </ReduxProvider>
  );
}

export default App;

import qs from 'query-string';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';
import {
  BackgroundPhotoStateModel,
  ListBackgroundByCategoryPhotoRequestModel,
  ListBackgroundByCategoryPhotoResponseModel,
} from '../../models/photo/background.model';

export const backgroundApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listBackgroundByCategoryApiAction: build.query<
      BackgroundPhotoStateModel['listBackgroundPhotos'],
      ListBackgroundByCategoryPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/background/getlist?${qs.stringify(payload)}`,
      }),
      transformResponse: (
        rawResult: ListBackgroundByCategoryPhotoResponseModel,
      ) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const {
  useListBackgroundByCategoryApiActionQuery,
  useLazyListBackgroundByCategoryApiActionQuery,
} = backgroundApi;

export const {
  endpoints: { listBackgroundByCategoryApiAction },
} = backgroundApi;

import { ReactComponent as CarouselDot } from './carousel_dot.svg';
import { ReactComponent as CarouselDotActive } from './carousel_dot_active.svg';
import { ReactComponent as PeopleError } from './people_error.svg';
import { ReactComponent as CameraReplace } from './camera_replace.svg';
import { ReactComponent as CameraTaking } from './camera_taking.svg';
import { ReactComponent as CircleDollar } from './circle_dollar.svg';
import { ReactComponent as Round } from './round.svg';
import { ReactComponent as Countdown } from './countdown.svg';
import { ReactComponent as BackSpace } from './back-space.svg';
import { ReactComponent as Shift } from './shift.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Rotate } from './rotate-icon.svg';
import { ReactComponent as Flip } from './flip-icon.svg';
import { ReactComponent as DeleteSticker } from './icon-delete-sicker.svg';
import { ReactComponent as Refresh } from './refresh.svg';

export const Icons = {
  CarouselDot,
  CarouselDotActive,
  PeopleError,
  CameraReplace,
  CameraTaking,
  CircleDollar,
  Round,
  Countdown,
  BackSpace,
  Shift,
  Warning,
  Rotate,
  Flip,
  DeleteSticker,
  Refresh,
};

import cx from 'classnames';
import React from 'react';
import Button, { ButtonProps } from './Button';
import './button.css';

export type Props = ButtonProps & {
  active?: boolean;
};

const SquareButton: React.FC<Props> = ({ className, active, ...rest }) => (
  <Button
    {...rest}
    className={cx('pb-square-button', className, {
      'pb-square-button-warning': !active,
    })}
    TypographyProps={{
      ...rest?.TypographyProps,
      className: 'pb-square-button-text',
    }}
  />
);

export default SquareButton;

import { Icons } from 'assets/icon/Icons';
import { AssetImage } from 'assets/v2/images/AssetImage';
import useConditionalTimeout from 'beautiful-react-hooks/useConditionalTimeout';
import useInterval from 'beautiful-react-hooks/useInterval';
import cx from 'classnames';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { BROADCAST_EVENTS } from 'constants/dom-element.const';
import { I18nNamespace } from 'constants/i18n.const';
import {
  CURRENCY_SYMBOL,
  SECOND_VAL,
  TIME_BANKING_DEBOUNCE_TIME,
  TIME_REFRESHABLE_DURATION,
} from 'constants/photo.const';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { emitSeqLog } from 'functions/seq-logging.func';
import { formatMoneyVN, mToSec } from 'helpers/math.helper';
import { diffFromDate, secondToTimeFormat } from 'helpers/time.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { toNumber } from 'lodash';
import { SocketEventDataModel } from 'models/photo/socket.model';
import moment from 'moment';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { useDebouncedCallback } from 'use-debounce';
import { useCheckPayment } from '../payment/useCheckPayment';
import './qr-code.css';

function QRCode() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getPrevPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();
  const { second } = usePageTimer();
  const {
    costOfPayment,
    isEnoughDeposit,
    handleBeforeNextWarningAboutMoney,
    handleCreateMomoQRCode,
  } = useCheckPayment();
  const {
    momoTransactionData,
    momoTransactionExpireDate,
    depositAmount,
    isMomoTransactionLoading,
  } = useAppSelector((state) => state.selfeApp);
  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  const [currentTime, setCurrentTime] = useState<Date | null>(
    moment().toDate(),
  );
  const [timeRefreshable, setTimeRefreshable] = useState<number>(
    mToSec(TIME_REFRESHABLE_DURATION),
  );

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // thời gian chuyển khoản còn lại
  const [, clearInterval] = useInterval(async () => {
    setCurrentTime(moment().toDate());
    setTimeRefreshable((s) => Math.max(s - 1, 0));
  }, SECOND_VAL);

  const handleRefreshQRCode = () => {
    setTimeRefreshable(mToSec(TIME_REFRESHABLE_DURATION));
    handleCreateMomoQRCode();
  };

  const handleQRBanking: any = useDebouncedCallback(
    useCallback(
      async (e: CustomEvent<SocketEventDataModel>) => {
        if (e?.detail?.orderId) {
          const bankingAmount = +toNumber(e?.detail?.amount) || 0;
          emitSeqLog?.({
            messageTemplate: `[QRCode] QR Banking: ${bankingAmount}`,
          });
          audio?.playDeposit?.();
          dispatch(
            setSelfeAppStateAction({
              depositAmount: depositAmount + bankingAmount,
            }),
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dispatch, depositAmount],
    ),
    TIME_BANKING_DEBOUNCE_TIME,
    { trailing: false, leading: true },
  );

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !isEnoughDeposit || !isOnline,
      }}
      handleBack={handleBack}
      handleContinue={handleBeforeNextWarningAboutMoney}
      className={cx({ 'qr-code__page-actions--vertical': isVerticalScreen })}
      hideNext
    />
  );

  useConditionalTimeout(
    () => {
      handleBeforeNextWarningAboutMoney();
    },
    SECOND_VAL * 2,
    costOfPayment <= depositAmount,
  );

  useEffect(
    () => () => {
      clearInterval();
    },
    [clearInterval],
  );

  // Add event broadcast bill acceptor
  useEffect(() => {
    document.addEventListener(BROADCAST_EVENTS.QR_BANKING, handleQRBanking);
    return () => {
      document.removeEventListener(
        BROADCAST_EVENTS.QR_BANKING,
        handleQRBanking,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="qr-code">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin mb-[6rem]">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:qrCode`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:qrCode`)}
            </Typography>
          </div>
        )}
        <div className="flex items-center justify-center qr-code__wrapper">
          <div className="qr-code__momo-wrapper">
            <div className="qr-code__momo-payment-info">
              <img src={AssetImage.MomoLogo} alt="momo icon" />
              <div className="qr-code__momo-payment-money-info">
                {costOfPayment <= depositAmount ? (
                  <>
                    <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                      {T(`${I18nNamespace.COMMON}:successBanking`)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                      {secondToTimeFormat(
                        diffFromDate(currentTime, momoTransactionExpireDate) ||
                          0,
                      )}
                    </Typography>
                    <div className="divide-vertical-line" />
                    <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                      <strong>
                        {formatMoneyVN(Math.max(costOfPayment - depositAmount))}{' '}
                        {CURRENCY_SYMBOL}
                      </strong>
                    </Typography>
                  </>
                )}
              </div>
            </div>
            <QRCodeCanvas
              style={{ height: 'auto', maxWidth: '100%', width: '45rem' }}
              value={momoTransactionData?.payUrl || ''}
            />
            {costOfPayment > depositAmount && (
              <div
                className={cx('qr-code__qr-refresh-wrapper', {
                  'qr-code__qr-refresh-wrapper--disabled': timeRefreshable > 0,
                  'qr-code__qr-refresh-wrapper--loading':
                    isMomoTransactionLoading,
                })}
              >
                <button
                  type="button"
                  className="qr-code__btn-qr-refresh"
                  onClick={handleRefreshQRCode}
                  disabled={timeRefreshable > 0}
                >
                  <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                    {timeRefreshable
                      ? secondToTimeFormat(timeRefreshable)
                      : T(`${I18nNamespace.COMMON}:qrRefresh`)}
                  </Typography>
                  <Icons.Refresh />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:qrCode`)} />
      )}
    </>
  );
}

export default QRCode;

import { PREFIX_API } from 'constants/api.const';
import { map } from 'lodash';
import {
  LayoutPhotoStateModel,
  ListLayoutPhotoRequestModel,
  ListLayoutPhotoResponseModel,
} from 'models/photo/layout.model';
import { baseSliceApi } from './base.slice-api';

export const layoutsApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listLayoutApiAction: build.query<
      LayoutPhotoStateModel['listLayoutPhotos'],
      ListLayoutPhotoRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/layout/getlist`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'LayoutPhoto', id } as const)),
        { type: 'LayoutPhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: ListLayoutPhotoResponseModel) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const { useListLayoutApiActionQuery } = layoutsApi;

export const {
  endpoints: { listLayoutApiAction },
} = layoutsApi;

import React from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import cx from 'classnames';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate } from 'react-router';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { AssetImage } from 'assets/v2/images/AssetImage';
import { useSoundContext } from 'context/SoundContext';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import './select-payment-method.css';

function SelectPaymentMethod() {
  const dispatch = useAppDispatch();
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { isVerticalScreen } = useAppSelector((s) => s.booth);
  const { getNextPath } = useNewPhotoLifeCycleStep();

  const { second } = usePageTimer({
    forwardFunc: () => handleSelectPaymentMethod()(),
  });

  // handle select frame
  const handleSelectPaymentMethod =
    (isUseQRCode = false) =>
    () => {
      audio?.playContinueClick?.();
      dispatch(
        setSelfeAppStateAction({
          isChoosePaymentMethod: true,
          isChooseQRCodeToPay: isUseQRCode,
        }),
      );
      navigate(getNextPath() || '');
    };

  return (
    <>
      <div className="select-payment-method">
        <TimerText second={second} />
        {!isVerticalScreen && (
          <div className="select-payment-method__title page-title-margin">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:selectPaymentMethod`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:selectPaymentMethod`)}
            </Typography>
          </div>
        )}
        <div className="select-payment-method__btn-group">
          <div
            className={cx(
              'dashed-border-select-frame select-payment-method__item',
            )}
          >
            <button
              type="button"
              className="select-payment-method__btn"
              onClick={handleSelectPaymentMethod()}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="uppercase text-center font-semibold"
                color={TypographyColor.SECONDARY}
              >
                {T(`${I18nNamespace.COMMON}:cash`)}
              </Typography>
              <img
                className="select-payment-method__mascot"
                src={AssetImage.CashMascot}
                alt=""
              />
            </button>
          </div>
          <div
            className={cx(
              'dashed-border-select-frame select-payment-method__item select-payment-method__item--right',
            )}
          >
            <button
              type="button"
              className="select-payment-method__btn"
              onClick={handleSelectPaymentMethod(true)}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="uppercase text-center font-semibold"
                color={TypographyColor.SECONDARY}
              >
                {T(`${I18nNamespace.COMMON}:qrCode`)}
              </Typography>
              <img
                className="select-payment-method__mascot"
                src={AssetImage.QRMascot}
                alt=""
              />
              <Typography
                variant={TYPOGRAPHY_VARIANTS.SMALL}
                className="select-payment-method__supported-bank-text"
                color={TypographyColor.SECONDARY}
              >
                {T(`${I18nNamespace.COMMON}:supportBankingVia`)}:
              </Typography>
              <div className="select-payment-method__supported-bank">
                <img src={AssetImage.MomoFillLogo} alt="momo icon" />
              </div>
            </button>
          </div>
        </div>
      </div>
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:selectPaymentMethod`)} />
      )}
    </>
  );
}

export default SelectPaymentMethod;

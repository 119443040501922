import qs from 'query-string';
import { map } from 'lodash';

import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from './base.slice-api';
import {
  GetStickerDetailPhotoRequestModel,
  GetStickerDetailPhotoResponseModel,
  ListStickerByCategoryPhotoRequestModel,
  ListStickerPhotoRequestModel,
  ListStickerPhotoResponseModel,
  StickerDetailPhotoModel,
  StickerPhotoStateModel,
} from '../../models/photo/sticker.model';

export const stickerApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listStickerApiAction: build.query<
      StickerPhotoStateModel['listStickerPhotos'],
      ListStickerPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/theme/getbylayoutid?${qs.stringify(payload)}`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'ThemePhoto', id } as const)),
        { type: 'ThemePhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: ListStickerPhotoResponseModel) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getStickerDetailApiAction: build.query<
      StickerDetailPhotoModel | null,
      GetStickerDetailPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/theme/getdetailbythemeid?${qs.stringify(
          payload,
        )}`,
      }),
      providesTags: (data) => [
        { type: 'ThemeDetailPhoto' as const, id: data?.id },
      ],
      transformResponse: (rawResult: GetStickerDetailPhotoResponseModel) =>
        rawResult?.response ?? null,
      extraOptions: { maxRetries: 1 },
    }),
    listStickerByCategoryApiAction: build.query<
      StickerPhotoStateModel['listStickerPhotos'],
      ListStickerByCategoryPhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/sticker/getlist?${qs.stringify(payload)}`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'StickerPhoto', id } as const)),
        { type: 'StickerPhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: any) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const {
  useListStickerApiActionQuery,
  useLazyListStickerApiActionQuery,
  useGetStickerDetailApiActionQuery,
  useLazyGetStickerDetailApiActionQuery,
  useListStickerByCategoryApiActionQuery,
  useLazyListStickerByCategoryApiActionQuery,
} = stickerApi;

export const {
  endpoints: { listStickerApiAction },
} = stickerApi;

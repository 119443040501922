import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ThemePhotoStateModel } from 'models/photo/theme.model';

// Define the initial state using that type
const initialState: ThemePhotoStateModel = {
  listThemePhotos: {
    data: [],
    totalRecord: 0,
  },
  loadingInList: false,
};

export const themePhotoSlice = createSlice({
  name: 'themePhoto',
  initialState,
  reducers: {
    setThemePhotoStateAction: (
      state,
      action: PayloadAction<Partial<ThemePhotoStateModel>>,
    ) => ({ ...state, ...action?.payload }),
  },
});

export const { setThemePhotoStateAction } = themePhotoSlice.actions;

export default themePhotoSlice.reducer;

import { PREFIX_API } from 'constants/api.const';
import {
  FirstPushTransactionRequestModel,
  FirstPushTransactionResponseModel,
} from 'models/photo/transaction.model';
import { baseSliceApi } from './base.slice-api';

export const transactionApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    firstPushTransactionApiAction: build.mutation<
      FirstPushTransactionResponseModel,
      FirstPushTransactionRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.API}/transaction/create`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const { useFirstPushTransactionApiActionMutation } = transactionApi;

export const {
  endpoints: { firstPushTransactionApiAction },
} = transactionApi;
